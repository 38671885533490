@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.tooltipContainer {
  width: 100%;
  position: relative;
  text-align: left;
  flex-grow: 1;
  box-sizing: border-box;
  display: block;

  .tooltipContent {
    top: 19px;
    position: absolute;
    background: $sidebar-bg;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    z-index: 999;
    transition: opacity 0.2s ease-in-out;
    @include thin-scrollbar;
    box-sizing: border-box;
    max-height: 150px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .geoValue {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 47px;

      .flagWrapper {
        width: 20px;
        height: 15px;
        border-radius: 2px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      span {
        display: inline-block;
      }
    }
  }
}
