$sidebar-bg: #171a21;
$new-white: #fff;
$dark-white: #E1E7FF;

$sidebar-active-bg: #5D87FF1A;
$new-blue: #335CFF;
$new-main-bg: #222632;
$icon-hover: rgba(225, 231, 255, .5);
$new-dark-grey-font: #818181;
$light-grey-font: #99A0AE;
$primary-blue: #476CFF;
$neutral-grey: #717784;
$orange: #E38804;

$scroll-bg: #FFFFFF1A;
$scroll-stick: #FFFFFF33;
$validation-red: #D02533;
$validation-green: #1FC16B;
$gradient-bg: linear-gradient(117.45deg, #232733 -3.91%, #2a2e3a 75.27%);
$red: #D02533;
$auth-form-grey: #CACFD8;
$blue: #5D87FF;
$navy-blue: #1F3BAD;
$main-components-bg: #222632;
$darkInputPlaceholderGrey: #CACFD8;
$validation-error: #d32f2f;