@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include form-buttons;
@include primary-button;

.choosePrelandingWrapper {
  //margin-top: 30px;

  @include page-inner-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  //height: 500px;
  flex-grow: 1;

  .pageInnerContent {
    //flex-direction: column;
    margin-bottom: 0;
  }

  .radioInputWrapper {
    padding-left: 13px;
    margin-bottom: 10px;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    //min-height: calc(52vh);
    //margin-top: 20px;

    .prelandingCardsWrapper {
      width: 100%;
      //flex-grow: 1;
      overflow: auto;
      //height: 400px;
      margin-bottom: 20px;
      position: relative;
      display: grid;
      //grid-template-columns: repeat(auto-fit, minmax(158px, 1fr));
      grid-template-columns: repeat(auto-fill, 158px);
      justify-content: space-around;
      //justify-content: space-evenly;


      row-gap: 25px;
      column-gap: 50px;

      @media (max-width: 800px) {
        column-gap: 25px;
        justify-content: space-evenly;
      }


      //.skeletonCardWrapper {
      //  width: 158px;
      //  height: 389.32px;
      //  overflow: hidden;
      //}

      .skeletonCard {
        width: 100%;
        //height: 100%;
        //width: 158px;
        height: 389.32px;
        //overflow: hidden;
        border-radius: 30px;
        //
        //span {
        //  width: 158px;
        //  height: 389.32px;
        //}
      }


      @include thin-scrollbar;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-content: center;

    .loadCardsBtnContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1300px) {
        margin-bottom: 30px;
      }

      .primaryBtn {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .buttons {
    margin-top: 10px;
  }
}