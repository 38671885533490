@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


@include blue-button;
@include primary-button;
@include table-actions-wrapper;
@include form-buttons;

.redirectorCardWrapper {
  padding: 24px;
  position: relative;
  background: $sidebar-bg;
  box-sizing: border-box;
  border: 1px solid $sidebar-bg;
  border-radius: 35px;
  min-width: 700px;
  flex: 1 1 0;

  &.active {
    border: 1px solid $primary-blue;
  }

  @media (max-width: 850px) {
    padding: 20px 12px;
  }

  @media (max-width: 1780px) {
    min-width: 500px;
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }

  .errorMessage {
    color: $validation-error;
    font-size: 15px;
    //margin-top: 5px;
    //text-align: left; /* Вирівнювання тексту по лівому краю */
    min-height: 16px;
    width: 100%; /* Додаємо ширину 100% для контейнера */
    white-space: nowrap;
    //white-space: normal; /* Дозволяє переносити текст на наступний рядок */
    //word-wrap: break-word; /* Переносить довгі слова */

    @media (max-width: 695px) {
      text-align: left; /* Вирівнювання тексту по лівому краю */
    }
  }

  .buttons {
    margin-top: 0;
    justify-content: center;
  }

  .editContent {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .header {
      display: flex;
      align-items: center;
      gap: 30px;
      min-height: 45px;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: stretch;
        gap: 20px;
      }

      .sub7 {
        flex: 1 1 auto;
        color: $light-grey-font;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        word-break: break-word;

        span {
          color: $new-white;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 1px;

          @media (max-width: 600px) {
            font-size: 16px;
          }
        }
      }
    }

    .applicationsTitle {
      color: $light-grey-font;
      font-size: 14px;
      font-weight: bold;
    }

    .applicationsContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .applicationsWrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;

        //& > .applicationOptionWrapper {
        //  margin-bottom: 25px; /* Загальний margin-bottom для всіх елементів */
        //}
        //
        //& > .applicationOptionWrapper:last-child {
        //  margin-bottom: 10px !important; /* Спеціальний margin-bottom для останнього елемента */
        //}

        //&:last-child {
        //  margin-bottom: 10px;
        //}


        .applicationOptionWrapper {
          //display: grid;
          //grid-template-columns: 1fr 170px auto; /* Три колонки */
          //grid-template-columns: 1fr; /* Три колонки */
          //margin-bottom: 25px;
          display: flex;
          gap: 30px;
          align-items: start; /* Вирівнювання зверху */
          position: relative;
          flex-wrap: wrap;
          //margin-bottom: 25px; /* Загальна відстань між всіма елементами */

          //&:last-of-type {
          //  margin-bottom: 10px; /* Спеціальна відстань для останнього елемента */
          //}

          .selectWrapper {
            grid-column: 1;
            flex: 1 0 auto;
            min-width: 350px;

            @media (max-width: 500px) {
              min-width: 300px;
            }
          }

          .wrapErrorContainer {
            display: flex;
            flex-direction: column;
          }

          .wrapContainer {
            display: flex;
            align-items: flex-start;
            gap: 30px;
            //flex: 1 0 0;
            //justify-content: flex-end;
            width: 260px;
          }

          .percentageWrapper {
            //grid-column: 2; /* Друга колонка для блоку з відсотками */
            display: flex;
            flex-direction: column;
            flex: 0 0 auto;
            align-items: flex-start; /* Вирівнюємо елементи по лівому краю */
            //min-width: 150px; /* Фіксована мінімальна ширина для блоку з відсотками */
            //width: 100%; /* Забезпечує динамічне розтягування при довгих повідомленнях */
          }

          .mockContainer {
            //grid-column: 3;
            width: 60px;
          }

          .actionsWrapper {
            //grid-column: 3; /* Третя колонка для дій */
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            z-index: 3;
            border-radius: 35px;
            margin-top: 6px;

            .actions {
              background: linear-gradient(
                              117.45deg,
                              rgba(255, 255, 255, 0) -3.91%,
                              rgba(255, 255, 255, 0.039) 75.27%
              );
              padding: 0;

              .removeBtn {
                width: 60px;
                padding: 3px;
              }

            }

            .actionsContainer {
              background: $sidebar-bg;
            }
          }

          .errorMessage {
            color: $validation-error;
            font-size: 15px;
            margin-top: 5px;
            text-align: left; /* Вирівнювання тексту по лівому краю */
            min-height: 16px;
            width: 100%; /* Додаємо ширину 100% для контейнера */
            white-space: nowrap;
            //white-space: normal; /* Дозволяє переносити текст на наступний рядок */
            //word-wrap: break-word; /* Переносить довгі слова */
          }
        }
      }

      .errorMessageWrapper {
        text-align: end;
      }
    }

    .addApplicationContainer {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      flex-wrap: wrap;

      .primaryBtn {
        font-size: 13px;
      }
    }
  }

  .displayContent {
    display: flex;
    flex-direction: column;
    gap: 24px;


    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: column;

      .sub7Title {
        color: $new-white;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        flex-grow: 1;
      }

      .sub7Wrapper {
        display: flex;
        flex-grow: 1;
        width: 100%;
        gap: 40px;


        .sub7Select {
          grid-column: 1;
          flex: 1 1 auto;
          width: 100%;
          min-width: 350px;

          @media (max-width: 500px) {
            //min-width: 300px;
          }
        }

        .actionsWrapper {
          flex: 1 1 auto;
          display: flex;
          border-radius: 35px;
          position: relative;
          z-index: 2;
          justify-content: flex-end;

          .actions {
            background: linear-gradient(117.45deg, rgba(255, 255, 255, 0) -3.91%, rgba(255, 255, 255, 0.039) 75.27%);

            button {
              button {
                padding: 6px !important;
              }
            }
          }

          .actionsContainer {
            background: $sidebar-bg;
          }
        }
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: stretch;
        gap: 20px;
      }

      .sub7 {
        flex: 1 1 auto;
        color: $light-grey-font;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        word-break: break-word;

        .sub7Select {
          grid-column: 1;
          flex: 1 0 0;
          min-width: 350px;

          @media (max-width: 500px) {
            min-width: 300px;
          }
        }


        span {
          color: $new-white;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 1px;

          @media (max-width: 600px) {
            font-size: 16px;
          }
        }
      }

      .actionsWrapper {
        flex: 1 1 auto;
        display: flex;
        border-radius: 35px;
        position: relative;
        z-index: 2;
        justify-content: flex-end;

        .actions {
          background: linear-gradient(117.45deg, rgba(255, 255, 255, 0) -3.91%, rgba(255, 255, 255, 0.039) 75.27%);
        }

        .actionsContainer {
          background: $sidebar-bg;
        }
      }
    }

    .applicationPercentagesWrapper {
      display: grid;
      grid-template-columns: 3fr .4fr 1fr;
      gap: 24px;
      align-items: center;
      width: 100%;

      .applicationHeader {
        display: contents;

        .cell {
          display: flex;
          align-items: center;
          color: $light-grey-font;
          font-size: 14px;
          font-weight: bold;

          &:first-child {
            justify-content: flex-start;
          }

          &:nth-child(2) {
            justify-content: center;
          }

          &:last-child {
            justify-content: flex-end;
          }
        }
      }

      .applicationContainer {
        display: contents;

        .applicationName {
          grid-column: 1;
          display: flex;
          align-items: center;
          word-break: break-word;
          font-size: 16px;
          color: $new-white;
          font-weight: 500;
        }

        .percentage {
          grid-column: 2;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          color: $new-white;
          font-weight: 500;

          .colorDot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }

        .service_count {
          grid-column: 3;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 16px;
          color: $new-white;
          font-weight: 500;
        }
      }
    }
  }
}