@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.popupWrapper {
  @include popup-wrapper;
  @include base-text-input;

  .popup {
    @include popup;
    max-width: 580px;

    p {
      margin: 6px 0;

      //@media (max-width: 900px) {
      //  margin: 3px 0;
      //  font-size: 14px;
      //}
    }

    .header {
      padding: 20px;
      display: flex;
      gap: 16px;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      //@media (max-width: 900px) {
      //  padding: 10px;
      //}

      .icon {
        //width: 40px;
        //height: 40px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        padding: 10px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        display: inline-flex;

        svg {
          //width: 24px;
          //height: 24px;

          path {
            //stroke: $new-white;
          }
        }
      }

      .popupInfo {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .title {
          letter-spacing: 1px;
          font-weight: 600;
          text-align: start;
          color: $new-white;

          @media (max-width: 900px) {
            font-size: 14px;
          }
        }
      }
    }

    .body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .error {
        margin-top: 5px;
        color: $validation-red;
      }

      //@media (max-width: 900px) {
      //  gap: 10px;
      //  padding: 20px;
      //}

      .inputName {
        color: $new-white;
        text-align: start;
        margin-bottom: 10px;

        @media (max-width: 900px) {
          font-size: 14px;
        }
      }
    }

    .footerContainer {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;

      @media (max-width: 400px) {
        gap: 30px;
      }
    }
  }
}
