.sortBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sortIcon {
  cursor: pointer;
  transition: .3s;

  &.rotation {
    transform: rotate(-180deg);
  }
}