@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include filters-wrapper;
@include stats-skeleton;
@include stats-table;
@include page-content;
@include page-inner-content;
@include one-row-header;

.geoAppStatsWrapper {
  position: relative;

  td {
    padding: 5px 15px !important;
  }
}