@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.popupWrapper {
  @include popup-wrapper;
  @include base-text-input;
  
  @media (min-width: 1001px) {
    display: none;
  }

  .closeBtn {
    top: 0;
    right: -15px;
    position: absolute;
  }

  .popupContent {
    position: relative;
  }
}