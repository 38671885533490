@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


.transactionsWrapper {
  position: relative;

  @include primary-button;
  @include blue-border-button;
  @include blue-border-icon-button;
  @include stats-table;
  @include stats-skeleton;
  @include page-content;
  @include table-actions-wrapper;
  @include filters-wrapper;
  @include page-inner-content;

  td {
    padding: 3px 15px;
    white-space: nowrap;
  }

  tbody tr {
    &:last-child {
      border-bottom: none;
    }
  }


  .hashContent {
    color: $new-white;
    white-space: nowrap;
  }

  tfoot {
    position: relative;
    z-index: 10 !important;
  }

  .switchPageContentContainer {
    background-color: $sidebar-bg;
    border-radius: 40px;
    padding: 15px 40px 0 40px;
    margin-bottom: 20px;
    display: flex;
    gap: 60px;
    justify-content: flex-start;
    position: relative;


    @media (max-width: 480px) {
      gap: 20px;
      justify-content: space-around;
    }

    .underline {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: $primary-blue;
      transition: transform 0.3s ease, width 0.3s ease;
    }

    .pageBtn {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400; /* використовуйте стандартний вага */
      color: $new-white;
      padding-bottom: 12px;
      transition: color 0.3s ease, transform 0.3s ease;
      position: relative; /* важливо для ::after */

      &.active {
        transform: scale(1.05);

        svg {
          path {
            fill: $primary-blue;
          }
        }
      }
    }

    .pageBtn {
      -webkit-font-smoothing: antialiased; /* для вебкіт-браузерів */
      -moz-osx-font-smoothing: grayscale; /* для Firefox */
    }

  }


  .btnsWrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 700px) {
      flex: 1 1 auto !important;
      .blueBorderIconBtn {
        flex: 1 1 auto !important;

      }
    }

  }

  .filters {
    column-gap: 60px !important;
    flex: 1 1 auto;
  }

  .filter {
    flex: 1 1 auto;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 590px) {
      flex-direction: column;
    }

    .userFilterContainer {
      @media (max-width: 830px) {
        width: 100%;
      }
    }

    .cleanBtnContainer {
      @media (max-width: 400px) {
        width: 100%;
      }
    }

    .filterContainer {
      display: flex;
      gap: 30px;

      @media (max-width: 1060px) {
        flex-direction: column;
      }
    }
  }

  .primaryBtn {
    padding-left: 25px;
  }

  .actionsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    border-radius: 40px;

    .actions {
      background: linear-gradient(
                      117.45deg,
                      rgba(255, 255, 255, 0) -3.91%,
                      rgba(255, 255, 255, 0.039) 75.27%
      );
      padding: 0;

      .manageTableFillBtn {
        box-sizing: border-box;
        width: 45px;
        padding: 2px 5px;

        button {
          padding: 4px 5px;
        }
      }

    }

    .actionsContainer {
      background: $sidebar-bg;
    }
  }
}
