@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';


.appCardWrapper {
  display: flex;
  font-family: Ubuntu, sans-serif;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;

  p {
    margin: 0;
  }

  .header {
    //flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    gap: 20px;
    //display: flex;
    //justify-content: space-between;
    background: linear-gradient(230.9deg, #171A21 50.06%, #10205F 80.97%, #0728AE 95.37%);

    .headerContainer {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

    .blockedGeoWrapper {

      @media (min-width: 800px) {
        display: none;
      }



      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      //width: 130px;
      gap: 8px;

      .blockedGeoList {
        display: flex;
        //max-width: 100px;
        //justify-content: flex-end;
        column-gap: 10px;
        //width: 105px;
        flex-wrap: wrap;

        li {
          font-size: 14px;
          color: $light-grey-font;
        }
      }
    }

    .info {
      display: flex;
      gap: 20px;
      width: 100%;
      flex: 1 1 auto;
      //.imageLink {



      .appIcon {
        background-color: $sidebar-bg;
        background-size: cover; /* Задає покриття всього контейнера */
        background-position: center; /* Центрує зображення */
        background-repeat: no-repeat; /* Зображення не повторюється */

        &.mocked {
          background-size: 38px 25px; /* Зображення зберігає свої пропорції */
        }


        width: 80px;
        height: 80px;
        border-radius: 20px;
      }

        //.appIcon {
        //
        //  width: 80px;
        //  height: 80px;
        //  border-radius: 20px;
        //}

      //}

      .details {
        width: 100px;
        flex: 1 1 auto;
        gap: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .appName {
          padding: 0;
          margin: 0;
          //line-height: 30px;
          font-size: 21px;
          font-weight: 700;
          flex: 1;
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $new-white;
          letter-spacing: .7px;
          
          @media (max-width: 1085px) {
            font-size: 17px;
          }
          
        }

        .type,
        .size {
          color: $light-grey-font;
        }

        .size {
          font-size: 15px;

          span {
            margin-left: 8px;
            padding-left: 8px;
            border-left: 1px solid $light-grey-font;
          }
        }

        .type {
          display: flex;
          align-items: center;
          gap: 8px;
          @media (max-width: 1085px) {
            flex-wrap: wrap;
          }

          svg {
            flex: 0 0 auto;
          }
        }
      }
    }

    .actionWrapepr {
      //display: flex;
      //flex-direction: column;
      //align-items: flex-end;
      //justify-content: space-between;

      
      
      @media (max-width: 800px) {
        .blockedGeoWrapper {
          display: none;
        }
      }
      
      @media (min-width: 800px) {

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        .blockedGeoWrapper {
          margin-top: 10px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          //width: 130px;
          gap: 8px;
          width: 105px;

          svg {
            flex: 0 0 auto;
          }

          .blockedGeoList {
            display: flex;
            //max-width: 100px;
            //justify-content: flex-end;
            column-gap: 10px;
            //width: 105px;
            flex-wrap: wrap;

            li {
              font-size: 14px;
              color: $light-grey-font;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      gap: 12px;
      align-items: flex-start;

      //@media (max-width: 400px) {
      //  flex-direction: column;
      //}
      //flex-wrap: wrap;
      //justify-content: flex-end;

      @media (max-width: 420px) {
        flex-wrap: wrap;
        //justify-content: center;
        justify-content: flex-end;
        gap: 10px;
      }

      .appStoreBtnLink {
        border: 1px solid $new-white;

        svg {
          width: 23px;
          height: 22px;
        }

        transition: all .4s ease;

        &:hover {
          transition: all .4s ease;
          background: $new-white;

          svg path {
            transition: all .4s ease;
            fill: $primary-blue;
          }
        }
      }

      .flowsLink {
        background: $primary-blue;
        transition: all .2s ease;

        &:hover {
          transition: all .2s ease;
          background: #2547D0;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .appStoreBtnLink,
      .flowsLink {
        box-sizing: border-box;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
      }
    }
  }

  .body {
    flex: 1 1 auto; /* Дозволяє контейнеру розтягуватися */
    background-size: cover; /* Задає покриття всього контейнера */
    background-position: center; /* Центрує зображення */
    background-repeat: no-repeat; /* Зображення не повторюється */
    height: 275px; /* Встановлює висоту контейнера */
    //border-radius: 0 0 20px 20px; /* Задає заокруглені кути знизу */
    border-radius: 0; /* Задає заокруглені кути знизу */


    &.mocked {
      background-position: center; /* Центрування зображення */
      background-repeat: no-repeat; /* Вимикаємо повторення зображення */
      background-size: auto; /* Зображення зберігає свої пропорції */
      background-color: $sidebar-bg;
    }

    .appImg {
      //width: 30px;
      width: 100%;
      height: 275px;
      border-radius: 0 0 20px 20px; /* Задає заокруглені кути знизу */
    }
  }

  .topGeoWrapper {
    //padding: 8px 20px 20px 20px;
    //padding: 10px 20px 8px 20px;
    padding: 0 20px;
    box-sizing: border-box;
    height: 66px;
    background: linear-gradient(275.66deg, #171A21 46.87%, #10205F 88.18%, #0728AE 107.41%);
    border-radius: 0 0 20px 20px;
    display: flex;
    //justify-content: center;
    align-items: center;

    @media (max-width: 420px) {
      padding: 0 10px;
    }

    .topGeoContainer {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-grow: 1;

      @media (max-width: 420px) {
        gap: 10px;
      }

      .topGeoTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        span {
          color: $auth-form-grey;
          font-size: 12px;
          margin-top: 3px;
        }
      }

      .topGeoList {
        display: flex;
        flex-grow: 1;
        //gap: 30px;
        justify-content: space-between;
        //justify-content: space-evenly;

        //display: grid;
        //grid-template-columns: repeat(auto-fit, minmax(0, auto)); // Автоматичне заповнення колонок
        //justify-content: start; // Вирівнювання елементів на початку
        //column-gap: 8px; // Відстань між елементами

        .geoItem {
          display: inline-flex;
          align-items: center;
          gap: 4px;

          .flagBg {
            width: 20px;
            height: 15px;
            border-radius: 2px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }

          .geoCode {
            padding-top: 2px;
            color: $new-white;
            font-size: 14px;
          }
        }
      }
    }
  }
}