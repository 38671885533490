@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


@include page-header;

.documentationWrapper {
  position: relative;
  @include page-content;
  @include one-row-header;

  .documentation {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .chapterHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    p {
      margin: 0;
    }

    .desc {
      color: $light-grey-font;
      font-size: 12px;
      font-weight: 400;
    }

    .name {
      color: $new-white;
      font-weight: 700;
      font-size: 20px;
    }
  }


  .chapterTitle {
    font-size: 15px;
    color: $new-white;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
    letter-spacing: .9px;
    margin: 0 0 16px 0;
  }


  .lessonsLinkList {
    grid-template-columns: repeat(auto-fill, 300px);
  }


  .faqLinkList {
    grid-template-columns: repeat(auto-fill, 384px);

    @media (max-width: 2000px) {
      grid-template-columns: repeat(4, minmax(300px, 1fr));
    }

    @media (max-width: 1650px) {
      grid-template-columns: repeat(3, minmax(300px, 1fr));
    }

    @media (max-width: 1400px) {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
    }

    @media (max-width: 650px) {
      grid-template-columns: repeat(1, minmax(300px, 1fr));
    }

    @media (min-width: 2000px) {
    }
  }

  .lessonsLinkList {
    //grid-template-columns: repeat(auto-fill, 300px);

    //@media (max-width: 2000px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    //}
  }


  .faqLinkList,
  .lessonsLinkList {
    display: grid;
    column-gap: 32px;
    row-gap: 20px;

    .lessonsCard {
      .lessonTag {
        pointer-events: none;
        display: flex;
        align-items: center;
        color: $new-white;
        gap: 10px;
        position: absolute;
        background: rgba(255, 255, 255, 0.3);
        padding: 8px 12px;
        border-radius: 0 0 11px 11px;
        left: 40px;
      }

      .body {
        min-height: 90px !important;
      }
    }

    .faqCard,
    .lessonsCard {
      width: 100%;
      border-radius: 35px;
      background: $gradient-bg;
      position: relative;
      box-sizing: border-box;

      &::before {
        content: '';
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        position: absolute;
        border-radius: 35px;
        z-index: -1;
        background-image: url('../../../assets/img/borderVector.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .cardImg {
        border-radius: 35px 35px 0 0;
        flex: 1 1 auto;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 192px;

        a {
          height: 100%;
          width: 100%;
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        padding: 10px 20px 20px 20px;
        gap: 12px;
        min-height: 115px;
        position: relative;
        flex-grow: 1;


        .name,
        .desc {
          color: $new-white;
        }

        .name {
          font-weight: 700;
          font-size: 18px;
        }

        .desc {
          font-weight: 400;
          font-size: 12px;
          height: 100%;
          flex-grow: 1;
        }

        .linkContainer {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .link {
            background: rgba(71, 108, 255, 0.25);
            border-radius: 40px;
            padding: 5px 10px;
            font-weight: 400;
            font-size: 12px;
            color: $primary-blue;

            svg {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
}