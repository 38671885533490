@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


.searchInputWrapper {
  width: 230px;

  @media (max-width: 670px) {
    width: 100%;
  }

  @include border-before-gradient;

  &::before {
    background: linear-gradient(91.65deg, rgba(255, 255, 255, 0.46) 3.17%, rgba(45, 45, 45, 0.46) 98.61%);
  }

  position: relative;
  border-radius: 35px;
  height: 36px;
  background: #171A21;
  box-sizing: border-box;

  .searchInput {
    background-color: transparent;
    border: none;
    width: 78%;
    outline: none;
    height: 100%;
    border-radius: inherit;
    padding: 0 20px 0 40px;
    color: #ffffff;
    position: relative;
    z-index: 1;
    font-weight: 500;
    font-size: 15px;

    &::placeholder {
      font-weight: 500;
      font-size: 15px;
      color: #99A0AE;
    }
  }

  span {
    position: absolute;
    top: 50%;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 24px;
    transform: translate(-50%, -50%);

    svg {
      width: 18px;
      height: 18px;
    }
  }
}