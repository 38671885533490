@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';


.notificationsPopup {
  width: 440px;
  background: $sidebar-bg;
  position: fixed;
  z-index: 20;
  top: 56px;
  left: calc(224px + 32px);
  border-radius: 32px;
  overflow: hidden;
  pointer-events: none;
  transition: max-height .5s ease, opacity .2s ease .25s;
  opacity: 0;
  max-height: 0;

  &.active {
    max-height: 100%;
    opacity: 1;
    pointer-events: auto;
    transition: max-height .6s ease, opacity .3s ease;
  }

  &.collapsed {
    left: calc(94px + 32px);
  }

  @media (max-width: 1000px) {
    left: 15px !important;
  }

  @media (max-width: 650px) {
    left: 13px !important;
    right: 13px !important;
    width: auto;
  }

  .popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    gap: 16px;
    border-bottom: 1px solid $scroll-bg;

    .iconWrapper {
      width: 40px;
      height: 40px;
      flex: 0 0 auto;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      box-sizing: border-box;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 17px;
        height: 17px;
      }
    }

    .popupTitle {
      flex: 1 1 auto;
      font-weight: 700;
      color: $new-white;
    }

    .closePopupBtn {
      padding-top: 4px;
    }
  }

  .popupBody {
    overflow-y: auto;
    //height: 100%;
    height: 413px;
    //height: 473px;

    @include scrollbar;

    .notificationsMessage {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      color: $new-white;
      font-size: 14px;
    }

    .notificationItem {
      display: flex;
      align-items: flex-start;
      padding: 8px 20px;
      gap: 12px;
      border-bottom: 1px solid #2B303B;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: #2B303B;
      }

      .notificationContent {
        display: flex;
        flex-direction: column;
        //gap: 7px;
        gap: 5px;
        flex: 1 1 auto;

        p {
          margin: 0;
        }

        .notificationText {
          color: $new-white;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }

        .timeWrapper {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;

          .date,
          .time {
            color: #99A0AE;
            font-size: 10px;
            font-weight: 300;
          }

          .deleteBtn {
            padding-top: 3px;

            &:disabled {
              opacity: .6;
              cursor: wait;
            }

            button {
              padding: 0;
            }

            svg {
              width: 10.5px;
              height: 11.6px;
            }
          }
        }

      }
    }
  }

  .popupFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $scroll-bg;
    padding: 16px 28px;

    .cleanNotificationsBtn,
    .readNotificationsBtn {
      display: inline-flex;
      gap: 5px;
      align-items: center;
      font-size: 13px;

      &:disabled {
        opacity: .6;
        cursor: wait;
      }
    }

    .readNotificationsBtn {
      color: $new-white;

      svg {
        padding-bottom: 2px;
        width: 14px;
        height: 14px;
      }
    }

    .cleanNotificationsBtn {
      color: $red;

      svg {
        width: 10px;
        height: 10px;

        path {
          stroke: $red;
        }
      }
    }
  }
}