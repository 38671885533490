@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.inputContainer {
  position: relative;


  .textInput {
    margin: 0 !important;
    width: 100%;
    flex: 1 1 auto;
    height: 48px !important;
    max-height: 48px !important;
    box-sizing: border-box;
    padding: 0 60px 0 20px;
    border-radius: 35px;
    border: 1px solid #171a21;
    background: #171a21;
    outline: none;
    font-size: 15px;
    color: $new-white;

    &::placeholder {
      color: #CACFD8;
    }

    &.error {
      border: 1px solid $validation-error;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      -webkit-text-fill-color: $new-white !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }
  }

  .lengthIndicator {
    position: absolute;
    color: $darkInputPlaceholderGrey;
    top: 50%;
    font-weight: 400;
    transform: translateY(-50%);
    right: 18px;
  }
}
