@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


.balancesWrapper {
  position: relative;

  @include stats-table;
  @include stats-skeleton;
  @include blue-border-button;
  @include primary-button;
  @include page-content;
  @include table-actions-wrapper;
  @include table-overflow-auto;
  @include page-inner-content;
  @include switch-content-border-bottom;

  .standartClickPrice {
    color: $new-white;
  }

  .pageContent {
    background: $sidebar-bg;
    border-radius: 32px;
    padding: 24px;
    box-sizing: border-box;
    margin-bottom: 50px;

    .switchPageContentContainer {
      border-radius: 0;
      margin-bottom: 0;

      .buttonsContainer {
        padding: 10px 40px 0 10px;
      }

      .underline {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: $primary-blue;
        transition: transform 0.3s ease, width 0.3s ease;
      }
    }

    .pageInnerContent {
      flex-direction: column;
    }
  }

  .skeletonTable {
    border-radius: 0 !important;
  }
}