@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";


.popupWrapper {
  @include popup-wrapper;
  @include blue-border-button;

  .popup {
    @include popup;
    max-width: 580px;

    .bodyContainer {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .iconWrapper {
        padding: 10px;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        display: inline-block;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
      }

      .title {
        letter-spacing: 1px;
        font-weight: 600;
      }

      .description {
        color: #CACFD8;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        line-height: 20px;

        a {
          color: $primary-blue;
        }
      }
    }

    .footer {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;

      @media (max-width: 400px) {
        gap: 30px;
      }
    }
  }
}
