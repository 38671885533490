@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";


@include page-content;
@include page-header-wrapper;

.appCatalogWrapper {
  position: relative;

  .appCatalog {

    @media (min-width: 1000px) {
      margin-bottom: 80px;
    }

    @media (max-width: 1085px) {
      display: flex;
      justify-content: center;
    }

    .content {
      display: grid;
      grid-gap: 20px;
      justify-content: space-between;

      &.collapsedSidebar {
        justify-content: space-around;

        @media (max-width: 2000px) {
          grid-template-columns: repeat(3, minmax(360px, 507px));
        }

        @media (max-width: 1330px) {
          grid-template-columns: repeat(2, minmax(360px, 507px));
        }

        @media (max-width: 1090px) and (min-width: 1000px) {
          justify-content: center;
          grid-template-columns: repeat(1, minmax(360px, 570px));
        }

        @media (max-width: 790px) {
          grid-template-columns: repeat(1, minmax(360px, 507px));
        }
      }


      .skeletonCard {
        height: 460px;
        width: 100%;
        border-radius: 20px;
      }

      @media (min-width: 2000px) {
        grid-template-columns: repeat(auto-fill, 507px);
        justify-content: space-around;
      }


      @media (max-width: 2000px) {
        grid-template-columns: repeat(3, minmax(360px, 1fr));
      }

      @media (max-width: 1470px) {
        grid-template-columns: repeat(2, minmax(360px, 1fr));
      }

      @media (max-width: 1100px) {
        justify-content: center;
        grid-template-columns: repeat(1, minmax(360px, 507px));
      }

      @media (max-width: 1100px) {
        justify-content: center;
        grid-template-columns: repeat(2, minmax(360px, 507px));
      }

      @media (max-width: 1090px) and (min-width: 1000px) {
        justify-content: center;
        grid-template-columns: repeat(1, minmax(360px, 507px));
      }

      @media (max-width: 1000px) {
        justify-content: center;
        grid-template-columns: repeat(1, minmax(340px, 507px));
      }
    }
  }
}
