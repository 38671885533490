@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include primary-button;
@include stats-table;
@include stats-skeleton;
@include page-content;
@include table-actions-wrapper;
@include page-inner-content;

.prelandingsTableWrapper {
  position: relative;

  td {
    padding: 0 15px;
  }
}