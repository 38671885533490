@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.postbackStatusWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;

  @include blue-border-button;
  @include primary-button;
  @include page-content;
  @include page-header-wrapper;
  @include page-inner-content;
  @include base-text-input;
  @include form-buttons;

  .tabHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      margin: 0;
    }

    .title {
      margin-top: 10px;
      color: $new-white;
      font-size: 16px;
      font-weight: 700;
    }

    .desc {
      color: $light-grey-font;
      font-size: 12px;
      font-weight: 300;
    }

  }

  .gradientBorderCustom {
    margin: 20px 0 15px 0;
  }

  .postbackStatusForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    margin-top: 25px;

    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .inputsWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .inputWrapper {
        p {
          color: $new-white;
          font-size: 14px;
          font-weight: 400;

          margin-bottom: 7px;
        }

        .error {
          font-size: 14px;
          margin-top: 8px;
          color: $validation-error;
        }
      }
    }
  }

  p {
    margin: 0;
  }

  .innerContentContainer {
    height: calc(100% - 45px);
    flex-grow: 1;
    @include thin-scrollbar(3px);
  }
}