@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.popupWrapper {
  @include popup-wrapper;
  @include base-text-input(#2e3137);

  .popup {
    @include popup;
    max-width: 580px;

    .headerContainer {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .iconWrapper {
        padding: 10px;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
      }

      .title {
        letter-spacing: 1px;
        font-weight: 600;
        color: $new-white;
        margin: 0;
      }
    }

    .bodyContainer {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 20px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      max-height: 500px;
      overflow-y: auto;
      gap: 20px;
      @include thin-scrollbar(3px);

      @media (max-width: 1400px) {
        height: 450px;
      }

      .fieldWrapper {
        p {
          margin-top: 0;
          margin-bottom: 7px;
          color: $dark-white;
          font-weight: 400;
          font-size: 14px;
        }

        .inputWrapper {
          position: relative;

          .error {
            font-size: 14px;
            margin-top: 8px;
            color: $validation-error;
          }

          .showPasswordBtn {
            z-index: 100;
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;
            box-sizing: border-box;
            width: 50px;
            height: 48px;

            svg {
              width: 24px;
              height: 24px;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .error {
        margin-top: 18px;
        color: $validation-red;
      }


    }

    .footerContainer {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;

      @media (max-width: 400px) {
        gap: 30px;
      }
    }

  }
}