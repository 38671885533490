@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


@include blue-button;
@include primary-button;
@include filters-wrapper;
@include pagination-container;
@include stats-table;
@include stats-skeleton;
@include table-actions-wrapper;
@include page-content;
@include page-inner-content;

.allRedirectorsWrapper {
  position: relative;
  //height: 100%;
  display: flex;
  //gap: 50px;
  //margin-bottom: 100px;
  margin-bottom: 20px;
  flex-direction: column;

  //height: 100vh;


  //.pageContent {
  //  //margin-top: 110px;
  //  margin-top: 95px;
  //  position: relative;
  //
  //  //margin-bottom: 20px;
  //
  //  //height: 100%;
  //  //height: calc(85vh - 70px);
  //  //min-height: 300px;
  //  //height: 100%;
  //  box-sizing: border-box;
  //  height: calc(100vh - 180px) !important;
  //
  //  //overflow: hidden;
  //
  //  display: flex; /* Додаємо Flexbox */
  //  flex-direction: column; /* Розміщуємо елементи вертикально */
  //  flex-grow: 1; /* Дозволяє цьому елементу займати доступний простір */
  //
  //  @media (max-width: 1000px) {
  //    //margin-top: 150px;
  //    margin-top: 130px;
  //    margin-bottom: 40px;
  //
  //    //height: calc(100vh - 235px) !important;
  //  }
  //
  //}

    //.paginationContainer {
    //  //margin-top: 20px;
    //  //margin: 20px 0;
    //  margin: 20px 0 0 0 !important;
    //
    //  height: 60px;
    //  //min-height: 30px;
    //  //min-height: none;
    //
    //  //height: 100%;
    //  @media (max-width: 1270px) {
    //    //height: 80px;
    //    margin: 40px 0 0 0 !important;
    //    //height: 200px;
    //  }
    //
    //}

  .contentContainer {
    min-height: 300px;
    height: 100%;
    overflow: auto;
    @include scrollbar;

    //height: 50vh;
    //min-height: 400px;
    //min-height: 300px;
    //height: 100%;
    //@include scrollbar;
    //height: 105%;

    //height: 50px;
    //height: 60%;
  }


  .skeletonsWrapper {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    width: 100%;

    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }

    .skeletonItem {
      height: 285px;
      border-radius: 35px;
      flex: 1 1 auto;
      min-width: 600px;
      position: relative;

      @media (max-width: 750px) {
        min-width: 300px;
      }

      .skeletonStyle {
        height: 100%;
        border-radius: 35px;
        width: 100%;
      }
    }


    .redirectorCardSkeleton {
      height: 285px;
      border-radius: 35px;
      width: 100%;
      min-width: 600px;
      flex: 1 1 auto;
      display: flex;
    }
  }

  .cardsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    flex-grow: 1; /* Дозволяє контейнеру займати весь доступний простір */
    min-height: 100px; /* Мінімальна висота для карток */
    //height: calc(100% - 200px); /* Встановлює висоту за вирахуванням 200px */
    //overflow-y: auto; /* Додає вертикальний скрол */
    @include scrollbar;




    .redirectorCardSkeleton {
      height: 285px;
      border-radius: 35px;
      width: 100%;
    }
  }
}