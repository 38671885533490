@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include filters-wrapper;
@include stats-skeleton;
@include stats-table;
@include page-content;
@include page-inner-content;
@include one-row-header;
@include primary-button;


.commonStatsWrapperWrapper {
  position: relative;

  td {
    padding: 5px 15px !important;
  }

  .downloadBtnWrapper {
    display: inline-flex;
    position: relative;

    @media (max-width: 900px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .primaryBtn {
      flex: 0 0 auto;
      border-radius: 40px 0 0 40px;

      @media (max-width: 460px) {
        flex: 1 1 auto;
      }

      svg {
        display: flex;
        margin-top: 1.5px;
        justify-content: center;
        align-items: flex-end;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        flex: 0 0 auto;

        path {
          fill: $new-white;
        }
      }
    }

    .arrowBtn {
      background-color: $navy-blue;
      height: 36px;
      padding: 0 13px 0 10px;
      border-radius: 0 40px 40px 0;
    }

    .langList {
      position: absolute;
      background: $sidebar-bg;
      //width: 88px;
      width: 100%;
      top: calc(100% + 8px);
      display: flex;
      flex-direction: column;
      padding: 8px;
      box-sizing: border-box;
      z-index: 500;
      font-weight: 400;
      font-size: 16px;
      border-radius: 25px;
      gap: 5px;


      .langOptionBtn {
        height: 36px;
        box-sizing: border-box;
        padding: 8px 12px 8px 16px;
        display: flex;
        width: 100%;
        align-items: center;
        border-radius: 20px;
        font-weight: 500;
        font-size: 14px;
        color: #F2F5FA;
        letter-spacing: 0.5px;
        //text-transform: uppercase;
        justify-content: space-between;

        &:hover {
          background: #1E2437;
        }

        svg {
          width: 13px !important;
          height: 11px !important;
        }
      }
    }
  }
}