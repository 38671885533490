@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


@include form-fields-wrapper;
@include base-text-input;

.popupWrapper {
  @include popup-wrapper;

  .popup {
    @include popup;
    max-width: 580px;

    p {
      margin: 0;
    }

    .bodyContainer {
      padding: 20px;


      .uploadWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .uploadPrelandBtn {
          display: inline-flex;
          align-items: center;
          gap: 8px;
          padding: 10px 40px;
          //border: 1px solid $primary-blue;
          box-sizing: border-box;
          font-size: 13px;
          color: $new-white;
          background: $primary-blue;
          border-radius: 40px;
          cursor: pointer;
          transition: .2s;

          svg path {
            fill: $new-white;
          }

          &:hover {
            background-color: $navy-blue;
          }

          &.zipArchiveError {
            //border: 1px solid $validation-error;
          }
        }

        .error {
          text-align: center !important;
        }
      }

      .fieldWrapper, .uploadWrapper {

        p {
          margin: 6px 0;
        }

        .fieldName {
          font-size: 14px;
          color: $new-white;
        }

        .error {
          margin-top: 8px;
          color: $validation-error;
        }
      }
    }

    .headerContainer {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        //padding: 10px;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        //display: inline-block;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
      }

      .title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        //letter-spacing: 1px;
        font-weight: 600;
        color: $new-white;
      }


      .description {
        color: #CACFD8;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        line-height: 20px;
      }
    }

    .footer {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;

      @media (max-width: 400px) {
        gap: 30px;
      }
    }
  }
}
