@import 'src/assets/styles/mixins';

.layoutContent {
  display: flex;
  flex: 1 1 auto;
  //min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  background: $main-components-bg;

  .container {
    margin-left: 224px;
    width: calc(100% - 224px);
    //margin-bottom: 20px;
    //height: 100%;
    //min-height: calc(100vh - 107px);
    min-height: 100vh;
    display: flex;
    gap: 0 !important;
    flex-direction: column;
    @include responsive-property(gap, 8, 15);
    position: sticky;
    background: $new-main-bg;

    &.collapsed {
      width: calc(100% - 94px);
      margin-left: 94px;

      @media (max-width: 1000px) {
        margin-left: 0;
        width: 100%;
      }
    }

    @media (max-width: 1000px) {
      width: 100%;
      margin-left: 0;
    }

    .pageContentWrapper {
      flex: 1 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      gap: 32px;
      padding: 0 32px 0 32px;
      //margin-top: 100px;
      //height: 100%;


      @media (max-width: 1000px) {
        padding: 0 15px;
      }

      @media (max-width: 400px) {
        padding: 0 13px;
      }
    }
  }
}