@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


.balancesWrapper {
  position: relative;

  @include filters-wrapper;
  @include stats-table;
  @include stats-skeleton;
  @include blue-border-button;
  @include primary-button;
  @include page-content;
  @include table-actions-wrapper;
  @include table-overflow-auto;
  @include page-inner-content;

  .filters {

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .selectWrapper {
      @media (max-width: 600px) {
        width: 100%;
      }
    }

    &.oneFilter {
      justify-content: flex-end;

      .selectWrapper {
        position: relative;
        min-width: 280px;
        flex: 0 1 auto;
        width: auto;
        box-sizing: border-box;

        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
}