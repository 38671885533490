@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.interfaceAppsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;

  @include filters-wrapper;
  @include stats-table;
  @include stats-skeleton;
  @include blue-border-button;
  @include primary-button;
  @include page-content;
  @include page-header-wrapper;
  @include filters-wrapper;
  @include stats-skeleton;
  @include table-actions-wrapper;
  @include table-overflow-auto;
  @include page-inner-content;
  @include switch-content-border-bottom;
  @include form-buttons;


  .buttons {
    margin: 0;
  }

  .tabHeader {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    color: $new-white;
  }

  .gradientBorderCustom {
    margin: 20px 0 10px 0;
  }

  .innerContentContainer {
    height: calc(100% - 45px);
    flex-grow: 1;
    @include thin-scrollbar(3px);


    .enterBtnTd {
      width: 83px;

      .primaryBtn {
        height: 30px;
      }
    }

    .actionsWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 3;
      border-radius: 40px;

      .actions {
        background: linear-gradient(
                        117.45deg,
                        rgba(255, 255, 255, 0) -3.91%,
                        rgba(255, 255, 255, 0.039) 75.27%
        );
        padding: 0;

        .manageTableFillBtn {
          box-sizing: border-box;
          width: 45px;
          padding: 2px 5px;

          button {
            padding: 4px 5px;
          }
        }

      }

      .actionsContainer {
        background: $sidebar-bg;
      }
    }


    .dragTr {
      z-index: 10;
      position: relative;

      td {
        border: 1px solid #476CFF;
      }

      .dragIconTd {
        border-right: none;
      }

      .appNameTd {
        border-right: none;
        border-left: none;
      }

      .imgTd {
        border-right: none;
        border-left: none;
      }

      .actionsTd {
        border-left: none;
      }
    }

    th {
      color: $new-white;
      font-size: 14px;
      font-weight: 700;

      &:first-child,
      &:last-child {
        background: inherit;

        &::before {
          border-radius: 0;
        }
      }
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;
    }

    tbody tr {
      width: 100%;
      z-index: 1;
      cursor: grabbing;


      &:hover {
        cursor: grab;
      }

      &:active {
        cursor: grabbing;
      }

      .dragIconTd {
        width: 100px;
      }

      .appNameTd {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        padding-right: 25px;

        p {
          margin: 0;
        }

        .appIcon {
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }

        .appInfo {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .appDetails {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 6px;


          .name {
            font-size: 14px;
            font-weight: 400;
          }

          .size {
            font-size: 12px;
            color: $light-grey-font;
            text-align: start;

            span {
              padding-right: 8px;
              border-right: 2px solid $light-grey-font;
              margin-right: 8px;
            }
          }
        }
      }

      .imgTd {
        width: 325px;
        .imgContainer {
          width: 325px;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .actionsTd {
        width: 80px;
        .appStoreBtnLink {
          border: 1px solid $new-white;
          box-sizing: border-box;
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          transition: all .4s ease;

          svg {
            width: 23px;
            height: 22px;
          }

          &:hover {
            transition: all .4s ease;
            background: $new-white;

            svg path {
              transition: all .4s ease;
              fill: $primary-blue;
            }
          }
        }
      }


      td {
        height: 80px;
        color: $new-white;
        font-size: 14px;
        font-weight: 700;
        background: #101318;
        padding: 3px 15px;
      }
    }
  }
}