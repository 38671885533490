@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include primary-button;
@include base-text-input(#171a21);
@include form-buttons;
@include form-fields-wrapper;
@include preland-elements;

.createPrelandingForm {
  .formContentWrapper {
    display: flex;
    gap: 50px;
    position: relative;

    @media (max-width: 1260px) {
      flex-direction: column;
    }
  }

  .buttons {
    margin-top: 50px;
  }
}