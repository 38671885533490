@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';


.editUserWrapper {
  position: relative;

  @include page-marking;
  @include primary-button;
  @include blue-border-button;
  @include blue-border-icon-button;
  @include page-content;
  @include page-inner-content;
  @include base-text-input;
  @include form-buttons;
  @include one-row-header;

  .pageMarking {
    margin-bottom: 10px;

    @media (max-width: 1000px) {
      margin-bottom: 20px;
    }
  }

  .userInfoHeader {
    padding: 16px 28px;
    background: $sidebar-bg;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 25px;

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: flex-start;
      .leftSide {
        width: 100%;
        justify-content: flex-start;
      }
      .rightSide {
        flex: 1 1 auto;
        width: 100%;
        justify-content: center;
      }
    }


    .leftSide {
      display: flex;
      align-items: center;
      gap: 20px;
      flex: 1 1 auto;
      //width: 100%;

      svg {
        width: 52px;
        height: 52px;
      }

      .userIconWrapper {
        width: 49px;
        height: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: $light-grey-font;
        box-sizing: border-box;
      }

      .nameInfo {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        width: 100px;

        p {
          margin: 0;
          color: $new-white;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 5px;
        }

        span {
          color: $light-grey-font;
          font-size: 14px;

        }

        p,
        span {
          flex: 1;
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .rightSide {
      display: flex;
      column-gap: 25px;
      row-gap: 20px;

      @media (max-width: 510px) {
        flex-direction: column;
        align-items: center;
      }

      .blueBorderIconBtn {
        margin-top: .5px;
      }

      .primaryBtn {
        padding: 13px 22px;
      }

      .redBtn {
        background: $red;
        border-radius: 40px;
        box-sizing: border-box;
        height: 36px;
        padding: 0 22px;
        //width: 143px;
        color: $new-white;
        transition: all .2s ease;
        flex: 0 0 auto;

        &:hover {
          background: #681219;
        }

        &:disabled {
          cursor: default;
          opacity: 0.6;
        }
      }
    }
  }

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }

  .formsWrapper {
    margin-top: 20px;
    display: flex;
    gap: 24px;

    @media (max-width: 1250px) {
      flex-direction: column;
      .formsColumnContainer1 {
        order: 2;
      }
      .formsColumnContainer2 {
        order: 1;
      }
    }

    .formsColumnContainer1,
    .formsColumnContainer2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .form {
      .userDetail {
        color: $light-grey-font;
        font-size: 12px;
        margin-bottom: 20px;

        span {
          margin-left: 5px;
          font-size: 14px;
        }
      }

      .inputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .inputWrapper {
          position: relative;

          p {
            margin-bottom: 5px;
            color: $new-white;
            font-weight: 400;
            font-size: 14px;
          }

          .error {
            font-size: 14px;
            margin-top: 8px;
            color: $validation-error;
          }

          .showPasswordBtn {
            z-index: 100;
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;
            box-sizing: border-box;
            width: 50px;
            height: 48px;

            svg {
              width: 24px;
              height: 24px;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .gradientBorderCustom {
      margin-bottom: 42px;
    }

    .changePasswordForm {

      .inputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .fieldWrapper {
          p {
            margin-bottom: 5px;
            color: $new-white;
            font-weight: 400;
            font-size: 14px;
          }

          .inputWrapper {
            position: relative;

            .error {
              font-size: 14px;
              margin-top: 8px;
              color: $validation-error;
            }

            .showPasswordBtn {
              z-index: 100;
              position: absolute;
              top: 2px;
              right: 0;
              cursor: pointer;
              box-sizing: border-box;
              width: 50px;
              height: 48px;

              svg {
                width: 24px;
                height: 24px;
              }

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }


    .form,
    .changePasswordForm {
      padding: 28px;
      width: 100%;
      border-radius: 35px;
      background: $sidebar-bg;
      position: relative;
      box-sizing: border-box;

      p {
        margin: 0;
      }

      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        font-size: 16px;
        color: $new-white;
        margin-bottom: 20px;
      }
    }
  }
}