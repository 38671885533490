@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include filters-wrapper;
@include stats-skeleton;
@include stats-table;
@include page-content;
@include page-inner-content;
@include one-row-header;

.cloacaStatsWrapper {
  position: relative;

  td {
    padding: 7px 15px;
  }


  .mismatch,
  .content {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  .mismatch {
    color: $validation-error;
  }
  .content {
    color: $primary-blue;
  }
}