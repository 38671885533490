@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include page-marking;
@include primary-button;
@include stats-table;
@include stats-skeleton;
@include page-content;
@include page-header-wrapper;
@include table-actions-wrapper;
@include base-text-input(#171a21);
@include form-buttons;
@include one-row-header;
@include switch-content-border-bottom;
@include form-fields-wrapper;
@include page-inner-content;

.redirectWrapper {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  //height: 500px;
  flex-grow: 1;

  .headerContainer {
    display: flex;
    column-gap: 50px;
    align-items: flex-end;
    row-gap: 20px;

    @media (max-width: 800px) {
      flex-direction: column;

      .fieldWrapper, .buttonContainer {
        width: 100%;
        flex: 1 1 auto;
      }
    }

    //@media

    .fieldWrapper {
      flex: 1 0 0;

      @media (max-width: 1450px) {
        flex: 1 0 auto;
      }


      p {
        margin: 6px 0;
      }

      .fieldName {
        font-size: 14px;
        color: $new-white;
      }

      .error {
        margin-top: 8px;
        color: $validation-error;
      }
    }

    .buttonContainer {
      flex: 1 0 0;
      display: flex;
      justify-content: flex-end;

      .primaryBtn {
        height: 40px;
        padding: 13px 20px;
      }
    }
  }

  .gradientBorderCustom {
    margin: 20px 0;
  }

  //.pageInnerContent {
  //  margin-top: 40px;
  //  height: 450px;
  //}

  .cardsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    //height: 450px;
    margin-top: 40px;
    overflow-y: auto;

    flex-grow: 1; /* Дозволяє контейнеру займати весь доступний простір */
    //min-height: 100px; /* Мінімальна висота для карток */
    //height: calc(100% - 200px); /* Встановлює висоту за вирахуванням 200px */
    //overflow-y: auto; /* Додає вертикальний скрол */
    @include thin-scrollbar(3px);




    .redirectorCardSkeleton {
      height: 285px;
      border-radius: 35px;
      width: 100%;
    }
  }
}