@import 'src/assets/styles/variables';


@mixin responsive-property($property, $minValue, $range, $minWidth: 1280, $maxWidth: 3840) {
  #{$property}: calc(#{$minValue}px + (#{$range} * ((100vw - #{$minWidth}px) / (#{$maxWidth} - #{$minWidth}))));
}

@mixin responsive-padding($minValue, $range, $top, $bottom) {
  @include responsive-property(padding-left, $minValue, $range);
  @include responsive-property(padding-right, $minValue, $range);

  padding-top: #{$top}px;
  padding-bottom: #{$bottom}px;
}

@mixin table-overflow-auto {
  .tableWrapper {
    .body {
      overflow: auto;
      min-height: 480px;
      max-height: none;
    }
  }
}

@mixin base-text-input($theme: rgba(255, 255, 255, 0.1)) {
  .textInput {
    margin: 0 !important;
    width: 100%;
    flex: 1 1 auto;
    height: 48px !important;
    max-height: 48px !important;
    box-sizing: border-box;
    padding: 0 60px 0 20px;
    border-radius: 35px;
    border: 1px solid $theme;
    background: $theme;
    outline: none;
    font-size: 15px;
    color: $new-white;

    &::placeholder {
      color: #CACFD8;
    }

    &.error {
      border: 1px solid $validation-error;
      color: $new-white !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      font-size: 15px;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      -webkit-text-fill-color: $new-white !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }
  }
}


@mixin blue-border-button {
  .blueBorderBtn {
    border-radius: 40px;
    color: $primary-blue;
    border: 1px solid $primary-blue;
    padding: 10px 35px;
    height: 36px;
    box-sizing: border-box;
    font-weight: 400;
    transition: all .2s ease;
    font-size: 13px;
    letter-spacing: .2px;

    &:hover {
      transition: all .2s ease;
      background: #E1E7FF;
      border: 1px solid #E1E7FF;
    }

    &:disabled {
      border: 1px solid rgba(153, 160, 174, 1);
      color: rgba(153, 160, 174, 1);
      cursor: not-allowed;

      &:hover {
        background: none;
        border: 1px solid rgba(153, 160, 174, 1);
      }
    }
  }
}

@mixin white-border-button {
  .whiteBorderBtn {
    border-radius: 40px;
    color: $new-white;
    border: 1px solid $new-white;
    padding: 10px 35px;
    //font-weight: 500;
    font-weight: 400;
    box-sizing: border-box;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;
    font-size: 13px;
    letter-spacing: .2px;

    &:hover {
      transition: all .2s ease;
      background: $new-white;
      border: 1px solid $new-white;
      color: $primary-blue;
    }
  }
}

@mixin letter-spacing($value) {
  letter-spacing: #{$value}px;
}

@mixin popup-width {
  width: 100%;

  @media (max-width: 1000px) {
    width: 90%;
  }
}


@mixin border-vector-before {
  content: '';
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  position: absolute;
  border-radius: 35px;
  z-index: -1;
  background-image: url('../img/borderVector.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin unauthorized-form {
  width: 366px;

  p {
    margin: 0;
  }

  .welcomeBlock {
    flex-direction: column;
    text-align: center;
    white-space: nowrap;
    font-size: 29px;
    color: $new-white;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    flex-wrap: nowrap;

    svg {
      width: 80px;
      height: 70px;
    }

    @media (max-width: 430px) {
      font-size: 26px;
    }
  }

  .formDescription {
    font-weight: 300;
    font-size: 14px;
    color: $auth-form-grey;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    text-align: center;
  }

  .linkItem {
    border-radius: 35px;
    background: #0e121b;
    position: relative;

    &::before {
      @include border-vector-before;
    }

    .link {
      width: 100%;
      display: flex;
      padding: 12px 16px;
      align-items: center;
      box-sizing: border-box;
      border-radius: 35px;
      position: relative;
      background: linear-gradient(117.45deg, rgba(255, 255, 255, 0) -3.91%, rgba(255, 255, 255, 0.04) 75.27%);
      font-size: 15px;
      color: $new-white;
    }
  }

  .formFieldsWrapper {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .fieldWrapper {
      .fieldDescription {
        margin: 0;
        color: $new-white;
        font-size: 16px;
        font-weight: 600;
      }

      .errorMessage {
        margin-top: 8px;
        font-size: 14px;
        color: #681219;
      }

      .inputWrapper {
        margin-top: 8px;
        border-radius: 35px;
        background: #0e121b;
        position: relative;

        &::before {
          @include border-vector-before;
        }

        &.wrapperError {
          &::before {
            background-image: none;
          }
        }

        .showPasswordBtn {
          z-index: 100;
          position: absolute;
          //top: 2px;
          top: 2px;
          right: 0;
          cursor: pointer;
          box-sizing: border-box;
          width: 50px;
          height: 48px;

          svg {
            width: 24px;
            height: 24px;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px transparent inset;
          box-shadow: 0 0 0 1000px transparent inset;
          -webkit-text-fill-color: #ffffff;
          transition: background-color 5000s ease-in-out 0s;
        }

        .input {
          width: 100%;
          display: flex;
          padding: 12px 16px;
          align-items: center;
          box-sizing: border-box;
          height: 48px;
          border-radius: 35px;
          position: relative;
          background: linear-gradient(117.45deg, rgba(255, 255, 255, 0) -3.91%, rgba(255, 255, 255, 0.04) 75.27%);
          outline: none;
          border: none;
          font-size: 15px;
          color: $new-white;

          &.password {
            padding-right: 50px;
          }

          &.error {
            border: 1.5px solid #681219;
          }

          &::placeholder {
            color: $auth-form-grey;
            font-size: 14px;
            font-weight: 300;
          }

          &:focus {
            background: linear-gradient(117.45deg, rgba(71, 108, 255, 0.216) -3.91%, rgba(71, 108, 255, 0) 75.27%);
          }
        }
      }
    }
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    margin-top: 50px;

    .enterBtn {
      flex: 1 1 auto;
      height: 40px;
      border-radius: 40px;
      font-size: 13px;
      color: $new-white;
      background: $navy-blue;
      transition: .2s;

      &:hover {
        background: $primary-blue;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: .6;

        &:hover {
          background-color: $navy-blue;
        }
      }
    }
  }
}

@mixin popup {
  @include popup-width;
  position: relative;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $sidebar-bg;
  box-sizing: border-box;
  transition: transform 0.2s;

  .closePopup {
    top: 26.5px;
    right: 20px;
    position: absolute;
  }

}

@mixin popup-wrapper {
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  height: 100vh;
  @include popup-position;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s;
  background-color: rgba(0, 0, 0, 0.5);

  &.active {
    opacity: 1;
    transform: scale(1);
  }
}


@mixin popup-position {
  width: calc(100vw - 224px);
  right: 0;

  @media (max-width: 1000px) {
    width: 100vw;
  }

  &.collapsed {
    width: calc(100vw - 94px);
    right: 0;

    @media (max-width: 1000px) {
      width: 100vw;
    }
  }
}


@mixin before-gradient-bg {
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(117.45deg, rgba(255, 255, 255, 0) -3.91%, rgba(255, 255, 255, 0.039) 75.27%);
    border-radius: 35px;
    z-index: 0;
  }
}

@mixin gradient-border {
  .gradientBorder {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: 35px;
    right: -1px;
    background: radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
  }
}

@mixin table-actions-wrapper {
  .actionsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;


    .actionsContainer {
      border-radius: 35px;
      background: $main-components-bg;
      position: relative;
      @include border-before-gradient;
    }

    .actions {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      //padding: 3px;
      box-sizing: border-box;
      border-radius: 35px;
      position: relative;
      background: $gradient-bg;
      background: linear-gradient(117.45deg, rgba(255, 255, 255, 0) -3.91%, rgba(255, 255, 255, 0.039) 75.27%);

      z-index: 1;


      button {
        button {
          padding: 5px !important;
        }
      }

      @include fillManageBtn;

      svg {
        width: 20px;
        height: 20px;
      }

      .removeBtn {
        transition: .3s;

        &:hover {
          transition: .3s;

          opacity: 0.8;
        }
      }

    }
  }
}

@mixin fillManageBtn {

  .removeBtn {
    &:hover {
      svg {
        opacity: 0.8;
      }
    }
  }

  .manageTableFillBtn,
  .manageTableStrokeBtn {
    transition: .3s;

    svg {
      width: 20px;
      height: 20px;
    }


    &:disabled {
      opacity: .3;
      cursor: not-allowed;
    }

    &:hover {
      transition: .3s;
    }
  }

  .manageTableFillBtn {
    svg path {
      fill: #E1E7FF;
    }

    &:disabled {
      svg path {
        fill: $new-white;
      }

      &:hover {
        svg path {
          fill: $new-white;
        }

      }
    }

    &:hover {
      svg path {
        transition: .3s;
        opacity: .7;
        //fill: $icon-hover;
      }
    }
  }

  .manageTableStrokeBtn {
    svg path {
      stroke: #E1E7FF;
    }

    &:disabled {
      svg path {
        stroke: $new-white;
      }

      &:hover {
        svg path {
          stroke: $new-white;
        }

      }
    }

    &:hover {
      svg path {
        transition: .3s;
        //stroke: $blue;
        opacity: .7;
        //stroke: $icon-hover;
      }
    }
  }
}

@mixin h5-style($font-size: 12) {

  .subTitle {
    //text-transform: uppercase;
    //text-transform: capitalize;
    color: $new-white;
    letter-spacing: 0.4px;
    font-size: #{$font-size}px;
    font-weight: 400;
    margin-bottom: 12px;
  }
}

@mixin blue-border-icon-button {
  .blueBorderIconBtn {
    font-family: Ubuntu, sans-serif;
    //width: 120px;
    width: auto;
    flex: 0 0 auto;
    box-sizing: border-box;
    height: 36px;
    //padding: 0 25px;
    padding: 13px 23px 13px 17px;
    position: relative;
    border-radius: 40px;
    //height: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: $primary-blue;
    border: 1px solid $primary-blue;
    white-space: nowrap;
    //background-color: $primary-blue;
    //border: 2px solid $new-primary-blue;
    font-size: 13px;
    font-weight: 400;
    transition: .2s;
    z-index: 1;
    //letter-spacing: .4px;

    &:hover {
      transition: all .2s ease;
      background: #E1E7FF;
      border: 1px solid #E1E7FF;
    }

    &:disabled {
      border: 1px solid rgba(153, 160, 174, 1);
      color: rgba(153, 160, 174, 1);
      cursor: not-allowed;

      &:hover {
        background: none;
        border: 1px solid rgba(153, 160, 174, 1);
      }
    }

    svg {
      display: flex;
      margin-top: 1.5px;
      justify-content: center;
      align-items: flex-end;
      //width: 11px;
      //height: 11px;
      margin-right: 10px;
    }
  }
}


@mixin page-marking {
  .pageMarking {
    color: $new-white;
    display: flex;
    gap: 15px;
    align-items: center;
    overflow-x: auto;
    padding-right: 20px;

    svg {
      flex: 0 0 auto;
    }

    .link {
      font-size: 14px;
      color: $new-white;

      &:hover {
        color: $primary-blue;
      }
    }

    span {
      font-size: 14px;
    }
  }
}


@mixin preland-sectors-wrapper {
  .sectorsWrapper {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 20px;
    padding: 20px;
    border-radius: 32px;
    background: $sidebar-bg;
    box-sizing: border-box;

    @media (max-width: 1650px) {
      grid-template-columns: 1fr;
    }

    .sectorInputWrapper {
      position: relative;
      //width: 100%;
      flex: 1 1 auto;

      .markWinSector {
        position: absolute;
        display: flex;
        align-items: center;
        left: 20px;

        label {
          font-size: 14px;
          color: $new-white;
          cursor: pointer;
        }
      }

      .textInput {
        min-width: 300px;
        background: rgba(255, 255, 255, 0.05);
        padding: 0 60px 0 96px;
      }

      .lengthIndicator {
        position: absolute;
        color: $darkInputPlaceholderGrey;
        top: 23px;
        font-size: 12px;
        font-weight: 400;
        transform: translateY(-50%);
        right: 18px;
      }
    }
  }
}

@mixin wheel-styles-wrapper {
  .wheelStylesWrapper {
    padding: 20px;
    border-radius: 32px;
    //background: #2A3447;
    background: $sidebar-bg;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    justify-items: center;
    row-gap: 20px;
    column-gap: 60px;

    .wheelBtn {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      box-sizing: border-box;
    }

    .wheelImg {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      box-sizing: border-box;

      &.active {
        border: 3px solid $primary-blue;
      }
    }
  }
}

@mixin iphone-preland-wrapper {
  .iPhoneWrapper {
    @media (max-width: 1000px) {
      display: none;
    }
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    position: relative;

    .wheelContainer {
      position: sticky;
    }
  }
}

@mixin preland-elements {
  @include iphone-preland-wrapper;
  @include wheel-styles-wrapper;
  @include preland-sectors-wrapper;
}


@mixin form-fields-wrapper {
  .formFieldsWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 0; // Забезпечує однакову ширину з iPhoneWrapper
    gap: 20px;

    .uniqueSettingsTitle {
      display: flex;
      align-items: center;

      span {
        color: $new-white;
        font-size: 12px;
        margin-left: 8px;
      }
    }

    .fieldWrapper {
      //display: flex;
      //flex-direction: column;
      p {
        margin: 6px 0;
      }

      .fieldName {
        font-size: 14px;
        color: $new-white;
      }

      .error {
        margin-top: 8px;
        color: $validation-error;
      }
    }
  }
}

@mixin primary-button {
  .primaryBtn {
    font-family: Ubuntu, sans-serif;
    //width: 120px;
    width: auto;
    flex: 0 0 auto;
    box-sizing: border-box;
    height: 36px;
    //padding: 0 25px;
    padding: 13px 25px 13px 20px;
    position: relative;
    border-radius: 40px;
    //height: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: $new-white;
    white-space: nowrap;
    background-color: $primary-blue;
    //border: 2px solid $new-primary-blue;
    font-size: 13px;
    font-weight: 400;
    transition: .2s;
    z-index: 1;
    //letter-spacing: .4px;

    &:hover {
      background-color: $navy-blue;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .6;

      &:hover {
        background-color: $primary-blue;
      }
    }

    svg {
      display: flex;
      //margin-top: 1.5px;
      justify-content: center;
      align-items: flex-end;
      width: 11px;
      height: 11px;
      margin-right: 10px;
    }
  }
}

@mixin blue-button {
  .blueButton {
    //width: 120px;
    width: auto;
    flex: 0 0 auto;
    padding: 0 25px;
    position: relative;
    border-radius: 13px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $new-white;
    white-space: nowrap;
    //padding-bottom: 2px;
    box-sizing: border-box;
    border: 2px solid $blue;
    font-size: 13px;
    font-weight: 400;
    transition: .3s;
    z-index: 1;
    letter-spacing: .4px;
    overflow: hidden;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover {
      color: $new-white;
    }

    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 100%;
      border-radius: 6px;
      top: 0;
      right: 0;
      background: $blue;
      box-shadow: 0 0 20px $blue;
      z-index: -1;
      transition: .3s;
    }

    &:hover:after {
      border-radius: 0;
      background: $blue;
      left: 0;
      width: 100%;
      z-index: -100;
    }
  }
}

//subTitle

@mixin buttons-wrapper {
  .buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
    //margin-right: 40px;
    //flex: 1 1 auto;
  }
}


@mixin stats-table {
  @include pagination-container;
  @include pagination-container-statistics;

  .geoTd {
    .value {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      //margin-right: 10px;

      .flagWrapper {
        width: 20px;
        height: 15px;
        border-radius: 2px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .mockedIcon {
        border-radius: 2px;
        width: 20px;
        height: 15px;
        background: $new-white;
      }
    }
  }

  .appTd {
    .value {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      //margin-right: 10px;

      .iconWrapper {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .mockedIcon {
        border-radius: 2px;
        width: 20px;
        height: 15px;
        background: $new-white;
      }
    }
  }

  td, th {
    vertical-align: middle;
    text-align: center;
  }

  tfoot tr th {
    position: sticky !important;
    z-index: 1;
    bottom: 0;
    left: 0;
  }

  .scrollTable {
    &:last-child {
      border-bottom: none;
    }
  }

  tbody tr {
    border-bottom: 1px solid #5a6a8559;
  }

  table, th, td {
    border-collapse: collapse;
  }

  td {
    font-size: 14px;
    color: $new-white;
    box-sizing: border-box;
    height: 48px;
    font-weight: 400 !important;
    padding: 15.5px 15px;

    p {
      white-space: nowrap;
      margin: 0;
    }
  }

  th {
    z-index: 10;
    white-space: nowrap;
    font-weight: 400;
    color: $light-grey-font;
    font-size: 14px;
    height: 44px;
    box-sizing: border-box;
    position: sticky !important;
    top: 0;
    left: 0;
    padding: 0 10px;
    background: $sidebar-bg;

    .headerContent {
      color: $light-grey-font;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:first-child {
      position: relative;
      background: $main-components-bg;

      &::before {
        border-radius: 40px 0 0 40px;
        z-index: -1;
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        background: $sidebar-bg;
        left: 0;
        right: 0;
      }
    }

    &:last-child {
      position: relative;
      background: $main-components-bg;

      &::before {
        border-radius: 0 40px 40px 0;
        z-index: -1;
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        background: $sidebar-bg;
        left: 0;
        right: 0;
      }
    }
  }

  .body {
    width: 100%;
    height: 100%;


    .skeletonWrapper {
      height: calc(100% - 45px);
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scroll-stick;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: $scroll-bg;
    }

  }

  table {
    background-color: transparent;
    width: 100%;
    overflow: auto;
  }
}

@mixin one-row-header {
  .pageContent {
    @media (max-width: 1000px) {
      margin-top: 70px;
    }
  }
}

@mixin page-inner-content {
  .pageInnerContent {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    height: 400px;
    margin-bottom: 20px;
    position: relative;

    @media (max-width: 900px) {
      height: 500px;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scroll-stick;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $scroll-bg;
      border-radius: 5px;
    }
  }

}

@mixin stats-skeleton {
  .skeletonTable {
    height: 100%;
    border-radius: 15px 15px 0 0 !important;

    &.total {
      border-radius: 15px 15px 35px 35px !important;
    }

    span {
      padding: 0;
      margin: 0;
    }
  }
}

@mixin scrollbar($width: 6px, $stick-color: $new-blue, $bg: rgba(255, 255, 255, 0.08)) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {

    background: $scroll-stick;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $scroll-bg;
    border-radius: 5px;
  }
}

@mixin thin-scrollbar($width: 6px, $height: 3px, $stick-color: $new-blue, $bg: rgba(255, 255, 255, 0.08)) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {

    background: $scroll-stick;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $scroll-bg;
    border-radius: 5px;
  }
}

@mixin h5-border-bottom {
  .subtitleBorderBottom {
    //color: $opacity-grey;
    color: $new-white;
    font-size: 15px;
    font-weight: 400;
    //border-bottom: 1px solid $opacity-grey;
    border-bottom: 2px solid $blue;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

@mixin h3-page-title {

  .pageTitle {


    color: $new-white;
    font-weight: 400;
    letter-spacing: .5px;
    @include responsive-property(font-size, 20, 7);

  }

}

@mixin invisible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin switch-content-border-bottom {
  .switchPageContentContainer {
    background-color: $sidebar-bg;
    border-radius: 40px;
    margin-bottom: 20px;
    overflow: hidden;

    .buttonsContainer {
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px 40px 0 40px;
      display: flex;
      gap: 50px;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      @include invisible-scrollbar;
    }

    .underline {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: $primary-blue;
      transition: transform 0.3s ease, width 0.3s ease;
    }

    .pageBtn {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400; /* використовуйте стандартний вага */
      color: $new-white;
      padding-bottom: 10px;
      transition: color 0.3s ease, transform 0.3s ease;
      position: relative; /* важливо для ::after */
      white-space: nowrap;

      &.disabled {
        color: #717784;
        cursor: default;
      }

      &.active {
        transform: scale(1.05);

        svg {
          path {
            fill: $primary-blue;
          }
        }
      }
    }

    .pageBtn {
      -webkit-font-smoothing: antialiased; /* для вебкіт-браузерів */
      -moz-osx-font-smoothing: grayscale; /* для Firefox */
    }

  }
}

@mixin content-buttons-wrapper {
  .contentButtonsWrapper {
    margin-top: 20px;
    border-radius: 31px;
    background: $sidebar-bg;
    align-items: center;
    display: inline-flex;
    padding: 6px 8px;
    gap: 20px;

    @media (max-width: 500px) {
      display: flex;
    }

    .contentBtn {
      height: 30px;
      box-sizing: border-box;
      width: 140px;
      background: $main-components-bg;
      border-radius: 40px;
      color: $new-white;
      transition: all .2s ease;

      @media (max-width: 500px) {
        width: 100%;
      }

      &.active {
        transition: all .2s ease;
        color: $primary-blue;
        background: #1E2437;
      }
    }
  }
}

@mixin search-gradient {
  .searchInputWrapper {
    width: 230px;
    margin-left: 30px;
    position: relative;
    border-radius: 35px; /* Закруглені кути */
    //padding: 10px; /* Відступ всередині контейнера */
    //background-color: #020024; /* Колір фону контейнера */
    height: 50px;
    //background: rgba(34, 38, 50, 1);
    background: #171A21;
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit; /* Наслідування закруглення кутів */
      padding: 2px; /* Товщина бордера */
      background: radial-gradient(79.43% 66.43% at 10% 45%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none; /* Запобігання взаємодії з псевдоелементом */
      transition: all .5s ease;

    }

    &.expanded {
      transition: all .5s ease;

      &::before {
        transition: all .5s ease;
        background-color: #6f727a;
        //background: radial-gradient(
        //                100% 100% at 100% 100%,
        //                #ffffff 0%,
        //                rgba(255, 255, 255, 0) 100%
        //);
      }
    }
  }
}

@mixin page-header-wrapper {
  .pageHeaderWrapper {
    z-index: 100;
    left: 0;
    right: 0;
    background-color: $main-components-bg;
    position: fixed;
    //padding: 10px 0 20px 0;
    padding: 10px 0;
    box-sizing: border-box;
    //min-height: 95px;
    min-height: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &.collapsedSidebar {
      .pageHeaderContainer {
        padding-left: 124px;

        @media (max-width: 1000px) {
          padding: 0 15px;
        }
      }
    }

    .pageHeaderContainer {
      padding-left: 256px;
      padding-right: 32px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 20px;
        padding: 0 15px;
      }
    }

    .staticElements {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;


      .leftSideWrapper {
        display: flex;
        gap: 20px;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
        }

        .notificationsBtn {
          position: relative;
          cursor: pointer;

          .bellWrapper {
            position: relative;
            display: inline-block;
            transform-origin: top center;
            will-change: transform;

            &:hover {
              animation: bellSwing 0.5s ease-in-out;
            }
          }

          .circle {
            pointer-events: none;
            position: absolute;
            top: 0;
            right: 2px;
            background: $new-white;
            border-radius: 50%;
            width: 6.6px;
            height: 6.6px;
          }
        }

        @keyframes bellSwing {
          0% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(15deg);
          }
          50% {
            transform: rotate(-15deg);
          }
          75% {
            transform: rotate(10deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }

        .standardInstallPrice {
          display: flex;
          align-items: center;
          gap: 5px;
          color: $new-white;
          font-size: 14px;
        }
      }

      .menuBtn {
        width: 36px;
        height: 36px;
        padding: 10px 9px 9px 9px;
        flex: 0 0 auto;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.1);
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        @media (min-width: 1000px) {
          display: none;
        }

        svg {
          width: 19px;
          height: 16px;
        }

        @media (max-width: 480px) {
          margin-left: 0;
        }
      }
    }

    .changingElements {
      display: flex;
      gap: 30px;
      align-items: center;

      @media (max-width: 1000px) {
        justify-content: flex-end;
        width: 100%;
        gap: 15px;
      }

      @media (max-width: 435px) {
        .primaryBtn {
          padding: 13px 20px 13px 15px;
          font-size: 15px;

          svg {
            margin-top: 0;
            margin-right: 7px;
          }
        }
      }
    }
  }
}

@mixin smooth-unfold-animation {
  pointer-events: none;
  transition: max-height .7s ease, opacity 0.2s ease .35s;
  opacity: 0;
  max-height: 0;

  &.active {
    max-height: 100%;
    opacity: 1;
    pointer-events: auto;
    transition:
            max-height .6s ease,
            opacity 0.3s ease;
  }
}

@mixin page-header-wrapper-collapsed {
  .pageHeaderWrapper {
    .pageHeaderContainer {
      padding-left: 124px;

      @media (max-width: 1000px) {
        padding: 0 15px;
      }
    }
  }
}

@mixin form-buttons {
  .buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    @include white-border-button;

    .whiteBorderBtn, .customPrimaryBtn {
      padding: 10px 30px;
      min-width: 140px;

      @media (max-width: 460px) {
        width: 100%;
      }
    }
  }
}

@mixin page-content {
  .pageContent {
    //margin-top: 110px;
    //gap: 20px;
    min-height: calc(100vh - 143px);
    margin-top: 70px;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
      margin-top: 120px;
    }

  }
}

@mixin border-before-gradient {
  &::before {
    content: '';
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

    position: absolute;
    border-radius: 35px;
    z-index: -1;
    transform: translateZ(0) !important;
  }
}


@mixin filters-wrapper {
  .filtersWrapper {
    border-radius: 35px;
    background: $main-components-bg;
    position: relative;
    margin-bottom: 20px;

    @include border-before-gradient;

    .filters {
      padding: 10px 16px;
      box-sizing: border-box;
      border-radius: 35px;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      background: $gradient-bg;

      .cleanBtnContainer {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        gap: 20px;
        flex-wrap: wrap;

        @include blue-border-button;

        @media (max-width: 600px) {
          justify-content: center;
          flex: 1 1 auto;
        }

        button {
          flex-shrink: 0;

          @media (max-width: 400px) {
            flex: 1 1 auto;
          }
        }
      }

      .selectDateWrapper {
        width: 250px;
        position: relative;
        flex: 1 0 auto;
        max-width: 300px;
        @media (max-width: 900px) {
          max-width: none;
          width: 100%;
        }

        //@media (max-width: 673px) {
        //  width: 100%;
        //}

        .selectIconWrapper {
          //z-index: 100;
          pointer-events: none;
          //z-index: 5;
          z-index: 3;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          position: absolute;
        }
      }

      .datePickersWrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        @media (max-width: 660px) {
          flex: 1 1 auto;
        }

        .datePicker {
          width: 160px;

          @media (max-width: 660px) {
            flex: 1 1 auto;
          }
        }
      }

      .selectWrapper {
        position: relative;
        min-width: 250px;
        flex: 1 0 auto;
        max-width: 100%;

        @media (max-width: 600px) {
          width: 100%;
        }

        .selectIconWrapper {
          pointer-events: none;
          z-index: 1;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          position: absolute;
        }
      }
    }

    .cloacaFilters {
      padding: 10px 16px;
      box-sizing: border-box;
      border-radius: 35px;
      //align-items: center;
      align-items: flex-start;
      justify-content: flex-start;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      width: 100%;
      background: $gradient-bg;


      .cleanBtnContainer {
        padding-top: 2px;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        height: 100%;

        @include blue-border-button;
        @include white-border-button;

        @media (max-width: 460px) {
          width: 100%;
        }

        .whiteBorderBtn, .blueBorderBtn {
          padding: 10px 30px;

          @media (max-width: 460px) {
            flex-grow: 1;
          }
        }
      }


      .selectDateWrapper {
        width: 280px;
        position: relative;
        flex: 1 0 auto;
        max-width: 320px;
        @media (max-width: 900px) {
          max-width: none;
          width: 100%;
        }

        //@media (max-width: 673px) {
        //  width: 100%;
        //}

        .selectIconWrapper {
          //z-index: 100;
          pointer-events: none;
          //z-index: 5;
          z-index: 3;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          position: absolute;
        }
      }

      .datePickersWrapper {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .datePicker {
          width: 160px;
        }
      }

      .selectWrapper {
        position: relative;
        min-width: 280px;
        flex: 1 0 auto;
        max-width: 100%;

        .errorMessage {
          margin-top: 5px;
          font-size: 14px;
          padding-left: 20px;
          color: $validation-red;
        }

        .selectContainerValidation {
          position: relative;
        }

        .selectIconWrapper {
          pointer-events: none;
          z-index: 1;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          position: absolute;
        }
      }
    }

  }
}


@mixin filters {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  //@media (max-width: 1700px) {
  //  flex-direction: column;
  //}
}

@mixin filter-wrapper {
  padding: 12px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  //box-shadow: 2px 4px 15px 0 rgba(11, 19, 18, 0.29);
  box-shadow: 2px 4px 30px 5px rgba(11, 19, 18, 0.15);
  margin: 30px 0;
}

@mixin soft-box-shadow {
  box-shadow: 2px 4px 30px 5px rgba(11, 19, 18, 0.15);
}


@mixin new-records-count {
  .newRecordsCount {
    position: absolute;
    left: 0;
    //margin-left: 10px;
    color: #B2B2B4;
    font-size: 12px;
    letter-spacing: 0.2px;
    //margin-right: auto; /* Притискає елемент до лівого краю */

    @media (max-width: 1000px) {
      position: relative;
    }

    span {
      margin-left: 5px;
      font-weight: 600;
    }
  }
}

@mixin pagination-container {
  .paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 20px;
    min-height: 29px;

    //margin-top: 30px;
    //min-height: 40px;

    @include new-records-count;

    @media (max-width: 1270px) {
      .newRecordsCount {
        position: relative;
      }
    }

    &.collapsed {

      @media (max-width: 1270px) {
        .newRecordsCount {
          position: absolute;
        }
      }

      @media (max-width: 1170px) {
        .newRecordsCount {
          position: relative;
        }
      }
    }
  }
}

@mixin pagination-container-statistics {
  .paginationContainerStatistics {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 20px;
    //margin-top: 20px;
    //min-height: 40px;
    min-height: 29px;

    @include new-records-count;

    .countPageSizeContainer {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .pageSizeDropdownContainer {
        position: absolute;
        right: 0;
      }

      @media (max-width: 1150px) {
        position: relative;

        .newRecordsCount,
        .pageSizeDropdownContainer {
          position: relative !important;
        }
      }
    }
  }
}


@mixin pagination-wrapper {
  .paginationWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
}

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin page-header {
  .pageHeader {
    word-wrap: break-word;
    min-height: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    @include h3-page-title;

    @media (max-width: 440px) {
      margin-bottom: 20px;
    }

  }
}

@mixin records-search-wrapper {
  .searchBar {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 20px;

    .recordsCount {
      color: $new-white;
      font-size: 15px;
      letter-spacing: 0.2px;

      span {
        margin-left: 5px;
      }

      .searchWrapper {
        display: flex;
        flex: 1 1 auto;
      }
    }
  }
}


@mixin noDataText {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: white;
}