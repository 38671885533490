

.spinWheel {
  transition: transform 4s ease-out;
  transform-origin: center; // Важливо для правильного центрування обертання

  &.spinning {
    animation: spin 4s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.prelandPreview {
  position: relative;
  //width: 375px;
  //min-height: 100vh;
  min-height: 100dvh;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 60px;
  box-sizing: border-box;

  width: 100%;
  overflow: hidden;
  //flex-direction: column;
  //height: 803px;

  //height: 100vh;

  @media(max-width: 431px) {
    height: 100vh;
  }

  @media(max-width: 541px) {
    height: 100%;
  }

  color: #F2F5FA;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .prelandLogo {
    margin-top: 10px;
    max-width: 200px;
    max-height: 150px;
  }

  .call {

    //.call {
    //  margin-top: 20px;
    //  font-weight: 700;
    //  font-size: 56px;
    //  font-family: Segoe UI, serif;
    //  //box-shadow: rgba(0, 0, 0, 1);
    //  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
    //}


    text-align: center;
    margin-top: 20px;
    font-weight: 700;
    font-size: 56px;
    line-height: 50px;
    //font-size: 8vw;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);

    @media(max-width: 431px) {
      font-size: 10vw;
    }

    font-family: Segoe UI, serif;
  }

  .content {
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //position: absolute;
    position: relative;

    margin-top: 100px;



    //margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;


    @media(max-width: 431px) {
      margin-top: 7vh;
    }

    .pointer {
      z-index: 10;
      position: absolute;
      top: 0;
    }

    .wheel {
      &.animated {
        @keyframes rotateAnimation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(1500deg);
          }
        }

        animation: rotateAnimation 4s ease-in-out;
      }
    }


    .nmb {
      position: absolute;
      color: red;
      top: 20%;
      left: 50%;
      transform: translate(10%, -50%) rotate(-70deg);
      transform-origin: center;
    }
  }


  .footer {
    //margin-top: 5vh;
    margin-top: 10vh;
    z-index: 10;

    .spinBtnWrapper {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      .btnBg {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1;

        width: 390px;
        //height: 90px;
        height: 100px;
      }

      .spinBtn {
        z-index: 2;
        box-sizing: border-box;
        padding: 10px 20px;
        text-transform: uppercase;
        //max-width: 290px;
        font-weight: 600;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: none;
        cursor: pointer;
        color: white;
        font-size: 30px;

        @media(max-width: 431px) {
          font-size: 5vw;
        }
      }
    }
  }

  .prelandPopup {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    //z-index: 20;
    z-index: 110;
    top: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;


    .popupBg {
      z-index: 1;
      //height: 100%;
      //min-height: 310px;
      //min-width: 300px;
      //height: 100%;
      width: 97%;
      position: absolute;
    }

    .popupContent {
      height: 327px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0 10px;
      box-sizing: border-box;
      z-index: 10;
      width: 100%;
      //width: 410px;
      //height: 100%;
      //max-height: 310px;
      //max-width: 410px;
      //height: 290px;

      //.popupBg {
      //  z-index: 1;
      //  position: absolute;
      //}

      .popupTextContent {
        top: 30%;
        font-weight: 600;
        text-align: center;
        font-size: 28px;

        @media(max-width: 431px) {
          font-size: 6vw;
        }
      }

      .popupTitle {
        font-weight: 600;
        text-align: center;
        font-size: 23px;
        top: 10%;

        @media(max-width: 431px) {
          font-size: 6vw;
        }
      }

      .popupBtn {
        //margin-bottom: 40px;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;


        //.spinBtn {
        //  color: white; /* Колір тексту */
        //  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
        //}

        .spinBtn {
          z-index: 2;
          box-sizing: border-box;
          padding: 10px 20px;
          //max-width: 290px;
          font-weight: 600;
          text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
          background-size: cover;
          text-transform: uppercase;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border: none;
          cursor: pointer;
          color: white;
          font-size: 30px;

          @media(max-width: 431px) {
            font-size: 5vw;
          }
        }

        .btnBg {
          position: absolute;
          z-index: 10;

          width: 390px;
          height: 120px;
        }

        .popupSpinBtn {
          z-index: 10;
          color: white;
          font-size: 30px;
          max-width: 290px;
          box-sizing: border-box;
          padding: 10px 40px;
          font-weight: 600;
          text-align: center;

          @media(max-width: 431px) {
            font-size: 5vw;
          }
        }
      }
    }
  }
}












//.spinWheel {
//  transition: transform 4s ease-out;
//  transform-origin: center; // Важливо для правильного центрування обертання
//
//  &.spinning {
//    animation: spin 4s linear infinite;
//  }
//}
//
//@keyframes spin {
//  from {
//    transform: rotate(0deg);
//  }
//  to {
//    transform: rotate(360deg);
//  }
//}
//
//.prelandPreview {
//  position: relative;
//  //width: 375px;
//  min-height: 100vh;
//  height: 100%;
//
//  width: 100%;
//  overflow: hidden;
//  //flex-direction: column;
//  //height: 803px;
//
//  //height: 100vh;
//
//  @media(max-width: 431px) {
//    height: 100vh;
//  }
//
//  @media(max-width: 541px) {
//    height: 100%;
//  }
//
//
//
//
//  //@media(max-width: 431px) {
//  //  margin-top: 7vh;
//  //}
//
//  //border: 12px solid #5a6a85;
//  //border-radius: 12px;
//  //display: flex;
//  //flex-direction: column;
//  //justify-content: space-between;
//  //align-items: center;
//  //display: flex;
//  //justify-content: center;
//  //align-items: center;
//  color: #F2F5FA;
//
//  .header {
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//    align-items: center;
//  }
//
//  .prelandLogo {
//    //width: 100%;
//    //height: 100%;
//    margin-top: 10px;
//    max-width: 200px;
//    max-height: 150px;
//  }
//
//  .call {
//    text-align: center;
//    margin-top: 20px;
//    font-weight: 700;
//    font-size: 56px;
//    line-height: 50px;
//    //font-size: 8vw;
//
//    @media(max-width: 431px) {
//      font-size: 8vw;
//    }
//
//    font-family: Segoe UI, serif;
//    box-shadow: rgba(0, 0, 0, 0.25);
//  }
//
//  .content {
//    //top: 50%;
//    //left: 50%;
//    //transform: translate(-50%, -50%);
//    //position: absolute;
//    position: relative;
//
//    margin-top: 100px;
//
//    @media(max-width: 431px) {
//      margin-top: 7vh;
//    }
//
//    //margin-top: 10vh;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//
//    .pointer {
//      z-index: 10;
//      position: absolute;
//      top: 0;
//    }
//
//    .wheel {
//
//      //width: 300px;
//      //height: 300px;
//
//      //width: 100%; // 100% ширини батьківського елемента
//      //height: 100%;
//
//      //border-radius: 50%;
//
//      //width: 80vw;
//      //height: 30vh;
//      //height: 200px;
//
//      &.animated {
//        @keyframes rotateAnimation {
//          0% {
//            transform: rotate(0deg);
//          }
//          100% {
//            transform: rotate(1500deg);
//          }
//        }
//
//        animation: rotateAnimation 4s ease-in-out;
//      }
//    }
//
//
//    .nmb {
//      position: absolute;
//      color: red;
//      top: 20%;
//      left: 50%;
//      transform: translate(10%, -50%) rotate(-70deg);
//      transform-origin: center;
//    }
//  }
//
//
//  .footer {
//    margin-top: 5vh;
//    z-index: 10;
//
//    .spinBtnWrapper {
//      cursor: pointer;
//      position: relative;
//      display: flex;
//      justify-content: center;
//      align-items: center;
//      margin-bottom: 30px;
//
//      .btnBg {
//        position: absolute;
//        z-index: 1;
//      }
//
//      .spinBtn {
//        z-index: 2;
//
//        box-sizing: border-box;
//        padding: 10px 20px;
//        max-width: 290px;
//
//        //font-size: 5vw;
//
//        //height: 100%;
//        //width: 100%;
//
//        @media(max-width: 431px) {
//          font-size: 5vw;
//        }
//
//
//
//        font-weight: 600;
//        //max-width: 290px;
//        box-sizing: border-box;
//        //padding: 0 5px;
//        background-size: cover;
//        background-repeat: no-repeat;
//        background-position: center;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        text-align: center;
//        border: none; /* Опціонально: видалити рамку кнопки */
//        cursor: pointer; /* Опціонально: змінити курсор на покажчик */
//        color: white; /* Колір тексту */
//        font-size: 30px;
//
//
//      }
//    }
//  }
//
//  .prelandPopup {
//    background-color: rgba(0, 0, 0, 0.5);
//    //height: 100vh;
//    height: 100%;
//    width: 100%;
//    z-index: 20;
//    top: 0;
//    bottom: 0;
//    position: fixed;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//
//    .popupContent {
//      position: relative;
//      display: flex;
//      flex-direction: column;
//      align-items: center;
//      justify-content: space-between;
//      padding: 15px 0 10px;
//      box-sizing: border-box;
//      width: 410px;
//      height: 290px;
//
//
//      .popupTextContent {
//        //position: absolute;
//        top: 30%;
//        font-weight: 600;
//
//        @media(max-width: 431px) {
//          font-size: 6vw;
//        }
//
//        text-align: center;
//        font-size: 28px;
//      }
//      .popupTitle {
//        font-weight: 600;
//        @media(max-width: 431px) {
//          font-size: 6vw;
//        }
//
//        text-align: center;
//        font-size: 23px;
//        //position: absolute;
//        top: 10%;
//      }
//
//      .popupBtn {
//        margin-bottom: 20px;
//        cursor: pointer;
//        position: relative;
//        display: flex;
//        justify-content: center;
//        align-items: center;
//
//        //position: absolute;
//        //bottom: 5%;
//        //font-size: 6vw;
//        //cursor: pointer;
//        //position: relative;
//
//
//        .btnBg {
//          position: absolute;
//          z-index: 1;
//        }
//
//        .popupSpinBtn {
//          z-index: 10;
//          //top: 0;
//          //bottom: 0;
//          //left: 0;
//          //right: 0;
//          //position: absolute;
//          ////background-size: cover;
//          ////background-repeat: no-repeat;
//          ////background-position: center;
//          //display: flex;
//          //align-items: center;
//          //justify-content: center;
//          //text-align: center;
//          //border: none;
//          //cursor: pointer;
//          color: white;
//          font-size: 30px;
//          //border-radius: 10px;
//          ////font-size: 5vw;
//          //z-index: 10;
//
//          @media(max-width: 431px) {
//            font-size: 5vw;
//          }
//          max-width: 290px;
//          //margin-bottom: 10px;
//
//          box-sizing: border-box;
//          padding: 10px 40px;
//          font-weight: 600;
//        }
//
//        //.spinBtnWrapper {
//        //  cursor: pointer;
//        //  position: relative;
//        //  display: flex;
//        //  justify-content: center;
//        //  align-items: center;
//        //  //width: 310px;
//        //  //height: 100px;
//        //  margin-bottom: 10px;
//        //  img {
//        //    z-index: 100;
//        //  }
//        //
//        //  .btnContent {
//        //    //position: absolute;
//        //    z-index: 150;
//        //    font-size: 30px;
//        //    //font-weight: 600;
//        //    text-transform: uppercase;
//        //  }
//        //}
//
//      }
//    }
//  }
//}