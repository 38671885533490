@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.usersTableWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;

  @include filters-wrapper;
  @include stats-table;
  @include stats-skeleton;
  @include blue-border-button;
  @include primary-button;
  @include page-content;
  @include page-header-wrapper;
  @include filters-wrapper;
  @include stats-skeleton;
  @include table-actions-wrapper;
  @include table-overflow-auto;
  @include page-inner-content;
  @include switch-content-border-bottom;

  .gradientBorderCustom {
    margin: 20px 0 10px 0;
  }

  .innerContentContainer {
    height: calc(100% - 45px);
    flex-grow: 1;
    @include thin-scrollbar(3px);


    .enterBtnTd {
      width: 83px;
      .primaryBtn {
        padding-left: 25px;
        height: 30px;
      }
    }

    .actionsWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 3;
      border-radius: 40px;

      .actions {
        background: linear-gradient(
                        117.45deg,
                        rgba(255, 255, 255, 0) -3.91%,
                        rgba(255, 255, 255, 0.039) 75.27%
        );
        padding: 0;

        .manageTableFillBtn {
          box-sizing: border-box;
          width: 45px;
          padding: 2px 5px;

          button {
            padding: 4px 5px;
          }
        }

      }

      .actionsContainer {
        background: $sidebar-bg;
      }
    }

    td {
      padding: 7px 15px;
    }

    th {
      color: $new-white;
      font-size: 14px;
      font-weight: 700;

      &:first-child,
      &:last-child {
        background: inherit;

        &::before {
          border-radius: 0;
        }
      }
    }

    td {
      background: #101318;
    }

    table {
      border-collapse: separate;
      border-spacing: 0 10px; // додає вертикальний відступ між рядками
    }

    tr {
      border-radius: 30px !important;
    }

    tbody tr {
      border: 1px solid rgba(134, 158, 252, 0.15);
      background: #101318;
      border-radius: 30px !important;


      td {
        color: $new-white;
        font-size: 14px;
        font-weight: 700;
      }
    }


    .redirectorCardSkeleton {
      height: 285px;
      border-radius: 35px;
      width: 100%;
    }
  }
}