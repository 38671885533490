@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";


.percentageWrapper {
  //cursor: not-allowed;
  //pointer-events: none;

  //&.disabled {
  //  //pointer-events: none;
  //  cursor: not-allowed;
  //
  //  //opacity: .6;
  //  //cursor: not-allowed;
  //
  //  .percentageInputWrapper {
  //    opacity: .6;
  //    pointer-events: none;
  //    //cursor: not-allowed;
  //  }
  //}

  .percentageInputWrapper {
    position: relative;
    display: inline-flex;
    align-items: center;




    //pointer-events: none;


    .percentageButtonsWrapper {
      //position: absolute;
      display: inline-flex;
      flex-direction: column;
      gap: 6px;
      justify-content: space-between;

      .percentageBtn {
        //position: absolute;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        //width: 20px;
        //height: 20px;
      }
    }

    .percentSign {
      position: absolute;
      font-size: 14px;
      color: $new-white;
      top: 50%; /* Центр по висоті */
      right: 40px;
      //left: 50%; /* Центр по ширині, якщо потрібно */
      transform: translate(-50%, -50%); /* Зсув на 50% по ширині та висоті */
    }

    .input {
      border: none; /* Прибирає рамку */
      background: #2E3037; /* Прибирає фоновий колір */
      outline: none; /* Прибирає обведення, яке з'являється при фокусі */
      border-radius: 40px;
      text-align: center;
      font-size: 14px;
      color: $new-white;
      //padding: 0; /* Зменшує внутрішні відступи */
      //width: 100%; /* Займає всю ширину контейнера */
      width: 150px;
      height: 48px;
      box-sizing: border-box;

      &.error {
        border: 1px solid $validation-error;
      }


      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      -moz-appearance: textfield;
      appearance: none;
    }
  }
}