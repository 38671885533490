@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include records-search-wrapper;
@include filters-wrapper;
@include buttons-wrapper;
@include stats-skeleton;
@include stats-table;
@include page-content;
@include page-inner-content;

.usersStatsWrapper {
  position: relative;

  td {
    padding: 5px 15px !important;
  }
}