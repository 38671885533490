@import 'src/assets/styles/variables';

@mixin mobile-padding {
  @media (max-width: 415px) {
    width: 35px;
    height: 35px;
  }
}

.pagination {
  display: flex;
  gap: 16px;

  .previousBtn {
    border-radius: 6px;
    background: #FFFFFF0D;
    width: 29px;
    height: 29px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile-padding;

    svg {
      width: 24px;
      height: 24px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;

      &:hover {
        svg path {
          stroke: $new-white;
        }
      }
    }

    &:hover {
      svg path {
        stroke: $primary-blue;
      }
    }
  }

  .countIndicator {
    cursor: default;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    color: $new-white;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    border-radius: 6px;
    background: #232D3F;
    width: 28px;
    height: 28px;
    @include mobile-padding;
  }

  .ellipsis {
    height: 29px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    span {
      color: $new-white;
      text-align: center;
      font-family: Gilroy, serif;
      font-size: 12px;
      font-weight: 400;
    }

    @include mobile-padding;
  }

  .pageItem {
    &.active {
      .paginationBtn {
        border-radius: 6px;
        border: 1px solid $primary-blue;
        color: $primary-blue;
      }
    }

    .paginationBtn {
      color: $new-white;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      padding-top: 2px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.05);

      width: 29px;
      height: 29px;

      @include mobile-padding;

      &:hover {
        color: $primary-blue;

        svg path {
          stroke: $primary-blue;
          fill: $primary-blue;
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
          svg path {
            stroke: $new-white;
            fill: $new-white;
          }
        }
      }
    }
  }

  .backPage {

    &.active {

      .paginationBtn {
        border-radius: 6px;
        border: 1px solid $primary-blue;
        color: $primary-blue;
      }
    }

    .paginationBtn {
      color: $new-white;
      padding-left: 5px;
      padding-top: 2px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.05);
      width: 29px;
      height: 29px;

      @include mobile-padding;

      &:hover {
        color: $primary-blue;

        svg path {
          stroke: $primary-blue;
          fill: $primary-blue;
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
          svg path {
            stroke: $new-white;
            fill: $new-white;;
          }
        }
      }
    }
  }

  .forwardPage {
    &.active {
      .paginationBtn {
        border-radius: 6px;
        border: 1px solid $primary-blue;
        color: $primary-blue;
        background: rgba(255, 255, 255, 0.05);
      }
    }

    .paginationBtn {
      color: $new-white;
      padding-left: 6px;
      padding-top: 2px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.05);
      width: 29px;
      height: 29px;

      @include mobile-padding;


      &:hover {
        color: $primary-blue;

        svg path {
          stroke: $primary-blue;
          fill: $primary-blue;
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
          svg path {
            stroke: $new-white;
            fill: $new-white;
          }
        }
      }
    }
  }

}