@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include filters-wrapper;
@include stats-skeleton;
@include stats-table;
@include page-content;
@include page-header-wrapper;
@include page-inner-content;

.postbackLogsStatsWrapper {
  position: relative;

  &.collapsedSidebar {
    @include page-header-wrapper-collapsed;
  }

  .urlTd {
    a {
      color: $new-white;
      max-width: 400px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .tdsClickidTd {
    white-space: nowrap;
  }

  td {
    padding: 7px 15px;
  }
}
