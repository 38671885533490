@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include page-content;
@include page-inner-content;
@include base-text-input(#171a21);
@include form-buttons;
@include one-row-header;

.changePasswordPageWrapper {
  position: relative;

  .pageContent {

    .pageTitle {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      gap: 8px;
      font-weight: 700;
      font-size: 18px;
      color: $darkInputPlaceholderGrey;
    }
  }

  .contentWrapper {
    display: flex;
    gap: 40px;

    @media (max-width: 1330px) {
      flex-direction: column;
    }


    .personalDataInfoContainer {
      .inputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .inputWrapper {
          position: relative;

          p {
            margin-bottom: 5px;
            color: $new-white;
            font-weight: 400;
            font-size: 14px;
          }

          .showPasswordBtn {
            z-index: 100;
            position: absolute;
            //top: 2px;
            top: 2px;
            right: 0;
            cursor: pointer;
            box-sizing: border-box;
            width: 50px;
            height: 48px;
            //transform: translate(-50%, -50%);

            svg {
              width: 24px;
              height: 24px;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .changePasswordContainer {
      .inputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .fieldWrapper {
          p {
            margin-bottom: 5px;
            color: $new-white;
            font-weight: 400;
            font-size: 14px;
          }

          .inputWrapper {
            position: relative;

            .error {
              font-size: 14px;
              margin-top: 8px;
              color: $validation-error;
            }

            .showPasswordBtn {
              z-index: 100;
              position: absolute;
              //top: 2px;
              top: 2px;
              right: 0;
              cursor: pointer;
              box-sizing: border-box;
              width: 50px;
              height: 48px;
              //transform: translate(-50%, -50%);

              svg {
                width: 24px;
                height: 24px;
              }

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }

    .personalDataInfoContainer,
    .changePasswordContainer {
      padding: 28px;
      //flex: 1 0 auto;

      @media (max-width: 600px) {
        padding: 25px 15px;
      }

      width: 100%;
      border-radius: 35px;
      background: $gradient-bg;
      position: relative;
      box-sizing: border-box;
      //height: 200px;

      p {
        margin: 0;
      }

      .title {
        font-weight: 700;
        color: $new-white;
        margin-bottom: 30px;
      }


      &::before {
        content: '';
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        position: absolute;
        border-radius: 35px;
        z-index: -1;
        background-image: url('../../../assets/img/borderVector.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}