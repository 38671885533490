@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";


.popupWrapper {
  @include popup-wrapper;
  @include primary-button;

  width: 100vw !important;

  .popup {
    @include popup;
    background-color: #0e121b;
    box-shadow: 9px 10px 24.4px 0 rgba(0, 0, 0, 0.31);
    max-width: 580px;

    p {
      margin: 0;
    }

    .bodyContainer {
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        box-sizing: border-box;

        img {
          width: 101px;
          height: 101px;
        }
      }

      .title {
        font-size: 32px;
        font-weight: 400;
        color: $new-white;
      }


      .description {
        color: #CACFD8;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        line-height: 20px;
        font-size: 16px;
        text-align: center;

        .support {
          margin-left: 2px;
          a {
            color: $primary-blue;
          }
        }
      }

      .spamEmail {
        font-size: 13px;
        color: $light-grey-font;
      }
    }

    .footer {
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;

      .primaryBtn {
        max-width: 380px;
        width: 100%;
      }

      @media (max-width: 400px) {
        gap: 30px;
      }
    }
  }
}
