@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


.editPixelApiWrapper {
  position: relative;
  @include base-text-input($sidebar-bg);
  @include page-content;
  @include one-row-header;

  .error {
    margin-top: 5px;
    min-height: 18px;
    color: $validation-red;
  }

  @include page-marking;
  
  .pixelForm {
    margin: 30px 0;


    .buttons {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      @include white-border-button;

      .whiteBorderBtn, .customPrimaryBtn {
        padding: 10px 30px;
        min-width: 140px;

        @media (max-width: 460px) {
          width: 100%;
        }
      }
    }

    .inputsWrapper {
      display: flex;
      flex-direction: column;
      column-gap: 40px;
      row-gap: 10px;
      width: 100%;
      //flex-wrap: wrap;

      @media (max-width: 680px) {
        flex-direction: column;
      }
    }

    .inputWrapper {

      display: flex;
      flex: 1 1 0;
      flex-direction: column;

      .textInput {
       flex: 1 0 auto !important;
      }

      .inputName {
        width: 100%;
        color: $new-white;
        text-align: start;
        margin-bottom: 10px;
      }
    }
  }
}