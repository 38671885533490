@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.pageSizeDropdown {
  cursor: pointer;
  //position: absolute;
  //right: 0;

  @media (max-width: 1450px) {
        //position: relative;
    }

  .dropdown {
    display: flex;
    justify-content: center;
    background-color: $sidebar-bg;
    border-radius: 36px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #B2B2B4;
    //gap: 8px;
    height: 30px;
    padding: 0px 12px 0 16px;
    box-sizing: border-box;
    letter-spacing: 0.5px;

    svg {
      width: 22px;
      height: 25px;

      path {
        transform: scale(1);
        transform-origin: center;
      }
    }
  }

  .sizeList {
    position: absolute;
    background: $sidebar-bg;
    width: 100%;
    bottom: calc(100% + 8px);
    display: flex;
    flex-direction: column;
    padding: 4px;
    box-sizing: border-box;
    z-index: 500;
    font-weight: 400;
    font-size: 16px;
    border-radius: 15px;
    gap: 5px;


    li {
      padding: 6px 0;
      text-align: center;
      border-radius: 23px;
      font-weight: 400;
      font-size: 15px;
      color: #F2F5FA;
      letter-spacing: 0.5px;

      &:hover {
        background: rgba(85, 120, 218, 0.50);
      }
    }
  }
}