@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


@include blue-button;
@include primary-button;
@include filters-wrapper;
@include pagination-container;
@include stats-table;
@include stats-skeleton;
@include table-actions-wrapper;
@include page-content;
//@include page-header-wrapper;
@include page-inner-content;
@include switch-content-border-bottom;

.flowsWrapper {
  position: relative;

  &.collapsedSidebar {
    @include page-header-wrapper-collapsed;
  }

  .switchPageContentContainer {
    background-color: $sidebar-bg;
    border-radius: 40px;
    padding: 15px 40px 0 40px;
    margin-bottom: 20px;
    display: flex;
    gap: 60px;
    justify-content: flex-start;
    position: relative;


    @media (max-width: 480px) {
      gap: 20px;
      justify-content: space-around;
    }

    .pageBtn {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400; /* використовуйте стандартний вага */
      color: $new-white;
      padding-bottom: 12px;
      transition: color 0.3s ease, transform 0.3s ease;
      position: relative; /* важливо для ::after */

      &.active {
        transform: scale(1.05);

        svg {
          path {
            fill: $primary-blue;
          }
        }
      }
    }

    .pageBtn {
      -webkit-font-smoothing: antialiased; /* для вебкіт-браузерів */
      -moz-osx-font-smoothing: grayscale; /* для Firefox */
    }

  }

  .tabDescription {
    color: #717784;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    margin-left: 40px;
    line-height: 17px;

    @media (max-width: 1000px) {
      margin: 0 10px 20px 10px;
      text-align: center;
      align-items: flex-start;
    }

    svg {
      flex: 0 0 auto;
    }
  }

  .domainTd {
    position: relative;
    //display: inline-flex;
    //align-items: center;

    .domainTdContent,
    .expiredDomainTdContent {
      display: inline-flex;
      align-items: center;
      gap: 11px;
      .domainName {
        white-space: nowrap;
        //color: $neutral-grey;
      }

      .tooltipButton {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        .domainTooltip {
          width: 180px; /* Встановіть фіксовану ширину тултипу */
          max-width: 180px; /* Запобігає розширенню понад цей розмір */
          background: $sidebar-bg;
          border-radius: 8px;
          z-index: 10;
          position: absolute;
          top: 50%;
          left: 100%;
          color: $new-white;
          margin-left: 20px;
          //right: -40px;
          transform: translateY(-50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center; /* Центрує текст */
          word-wrap: break-word; /* Розбиває довгі слова */
          overflow-wrap: break-word; /* Альтернатива для переносу тексту */
          //padding: 10px; /* Відступи для кращої читабельності */
          padding: 2px 5px;
          box-sizing: border-box; /* Враховує padding у розмірах елемента */

          &.adjustLeft {
            left: auto;
            right: 100%; /* Тултип буде зміщений вліво */
          }

          &.adjustTop {
            top: auto;
            bottom: 100%; /* Тултип буде зміщений вгору */
          }

          &.orange {
            border: 1px solid $orange;
            width: 360px; /* Встановіть фіксовану ширину тултипу */
            max-width: 360px; /* Запобігає розширенню понад цей розмір */
          }

          &.red {
            border: 1px solid $red;
          }

          span {
            font-size: 12px;
            margin: 3px 0; /* Додає відступи між абзацами */
            line-height: 1.6; /* Покращує читабельність тексту */
            font-weight: 400;
            word-break: break-word; /* Примушує розривати довгі слова */
            overflow-wrap: break-word; /* Для сучасних браузерів */
            text-align: center; /* Центрує текст */
          }

          .orangeUpperText {
            margin-bottom: 10px;
          }

          .bold {
            font-weight: 700;
          }

        }

        &.orange {
          svg path {
            fill: $orange;
          }
        }

        &.red {
          svg path {
            fill: $red;
          }
        }
      }

      svg path {
        //fill: $orange;
      }
    }

    .expiredDomainTdContent {
      color: $red;
    }

  }

  td {
    padding: 0 15px;
    height: 48px !important;
    box-sizing: border-box !important;
  }

  .statusTd {
    width: 150px;
    box-sizing: border-box;
    .statusIndicator {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      flex-wrap: nowrap;
      gap: 8px;
    }
  }

  .actions {
    padding: 1px 5px;

    .attentionBtn {
      svg path {
        fill: $red;
      }
    }
  }

  .geoTd {
    height: 100%;
    //height: 48px !important;
    //box-sizing: border-box !important;
    //justify-content: flex-start;

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1 1 auto;

    .tdContentContainer {
      //height: 100%;
      width: 200px;
      flex: 1 1 auto;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }


    .iterativeContentWrapper {
      position: relative;
      text-align: left;
      flex-grow: 1;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      //box-sizing: border-box !important;
      //height: 19px !important;
      //height: 48px;

      overflow: hidden;

      .value {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        margin-right: 10px;

        .iconWrapper {
          width: 20px;
          height: 15px;
          border-radius: 2px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .mockedIcon {
          border-radius: 2px;
          width: 20px;
          height: 15px;
          background: $new-white;
        }
      }
    }
  }


  .applicationTd {
    position: relative;
    height: 48px !important;
    box-sizing: border-box !important;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex: 1 1 auto;

    .tdContentContainer {
      width: 200px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }


    .applicationContentWrapper {
      position: relative;
      text-align: left;
      flex-grow: 1;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      //box-sizing: border-box !important;
      //height: 19px !important;

      .appValue {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        margin-right: 10px;

        .iconWrapper {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .mockedIcon {
          border-radius: 2px;
          width: 20px;
          height: 15px;
          background: $new-white;
        }
      }
    }
  }

  .geoContentWrapper {
    position: relative;
    //max-width: 180px;
    max-width: 215px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;

    .geoValue {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      margin-right: 10px;

      .flagWrapper {
        //flex-shrink: 0; /* Запобігання стисненню контейнера для зображення */
        //flex: 0 0 0;
        width: 20px;
        height: 15px;
        border-radius: 2px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .mockedFlag {
        border-radius: 2px;
        width: 20px;
        height: 15px;
        background: $new-white;
      }
    }
  }

  .appsContent {
    position: relative;
    max-width: 270px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;

    button {
      position: absolute;
      left: 0;
      width: 100%;
      cursor: text;
    }
  }

  .actionsTd {
    position: relative;
    width: 100px;
    box-sizing: border-box;

    .flowActionsPopup {
      position: absolute;
      z-index: 10;
      top: 100%;
      right: calc(0px + 16.5px);
      display: flex;
      flex-direction: column;
      padding: 8px;
      background: $sidebar-bg;
      align-items: flex-start;
      flex: 1 1 auto;
      box-sizing: border-box;
      width: 218px;
      border-radius: 25px;
      gap: 8px;

      li {
        flex: 1 1 auto;
        width: 100%;
        display: flex;
        height: 32px;
        border-radius: 20px;


        &:hover {
          background: #1E2437;
        }

        button, a {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 8px;
          padding: 6px 8px 6px 10px;
          box-sizing: border-box;

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          svg {
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 12px;
            color: $new-white;
            white-space: nowrap;
          }
        }
      }
    }
  }


  .editNoteBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: $new-white;
    font-size: 15px;
  }

  .noteTd {
    width: 200px;
    box-sizing: border-box;
  }

  .editNoteField {
    display: inline-flex;
    align-items: center;


    .noteTextBtn {
      //white-space: nowrap;
      //text-overflow: ellipsis;
      //overflow: hidden;
      //max-width: 180px;
      position: relative;
      color: $primary-blue;
      //text-decoration: underline;
      //padding-bottom: 4px;
      display: flex;
      gap: 5px;
      align-items: center;
      cursor: pointer !important;

      .noteText {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 170px;
      }

      button {
        position: absolute;
        left: 0;
        width: 100%;
        //cursor: poi;
      }
    }
  }

  .createNoteField {
    display: flex;
    justify-content: center;
    align-items: center;

    .createNoteBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      font-weight: 500;
      color: $primary-blue;
      font-size: 14px;
      text-transform: uppercase;

      svg {
        margin-left: 5px;
        width: 13px;
        height: 13px;
      }
    }
  }
}


//.applicationTd {
//  position: relative;
//  display: flex;
//  justify-content: flex-start;
//  align-items: center;
//  flex-grow: 1;
//  width: 100%;
//
//  .applicationContentWrapper {
//    position: relative;
//    //max-width: 180px;
//    max-width: 400px;
//    //min-width: 200px;
//    flex-grow: 1;
//
//    white-space: nowrap;
//    text-overflow: ellipsis;
//    overflow: hidden;
//    //display: inline-block;
//    //display: block;
//
//    justify-content: center;
//    align-items: center;
//    text-align: center;
//
//    .appValue {
//      display: inline-flex;
//      align-items: center;
//      gap: 4px;
//      margin-right: 10px;
//
//      .iconWrapper {
//        //flex-shrink: 0; /* Запобігання стисненню контейнера для зображення */
//        //flex: 0 0 0;
//        width: 20px;
//        height: 20px;
//        border-radius: 50%;
//        background-size: cover;
//        background-position: center;
//        background-repeat: no-repeat;
//      }
//
//      .mockedIcon {
//        border-radius: 2px;
//        width: 20px;
//        height: 15px;
//        background: $new-white;
//      }
//    }
//  }
//}