@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


@include page-content;
@include page-header-wrapper;
@include one-row-header;
@include primary-button;

.page404Wrapepr {
  position: relative;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .letters404 {
      font-size: 140px;
      color: $new-white;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .messages {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      p {
        margin: 0;
        color: $light-grey-font;
        font-size: 16px;
        max-width: 450px;
        text-align: center;
        line-height: 24px;
      }
    }

    .buttonContainer {
      margin-top: 35px;
      .primaryBtn {
        padding: 13px 25px;
      }
    }
  }

}

.loader {
  width: 4px;
  height: 86px;
  background: #fff;
  margin-bottom: 50px;
  position: relative;
  animation: shake 1s linear infinite alternate,
  lightup 1s linear infinite;
  transform-origin: 0 0;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    width: 32px;
    height: 16px;
    box-sizing:border-box;
    border: 5px solid $primary-blue;
    border-top: none;
    border-radius: 0 0 20px 20px;
  }

  &::after {
    content: '';
    left: 50%;
    bottom: 0;
    position: absolute;
    transform: translateX(-50%);
    width: 64px;
    height: 32px;
    border-radius: 50px 50px 0 0;
    background: #fff;
  }
}


@keyframes shake {
  0% { transform: rotate(10deg)  }
  100% { transform: rotate(-10deg)  }
}
@keyframes lightup {
  0% ,20% , 40% {
    opacity: 0
  }
  10%, 30% , 50% , 100% {
    opacity: 1
  }
}
