@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.popupWrapper {
  @include popup-wrapper;

  .popup {
    @include popup;
    max-width: 580px;

    .headerContainer {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .iconWrapper {
        padding: 10px;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
      }

      .title {
        letter-spacing: 1px;
        font-weight: 600;
      }
    }

    .bodyContainer {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 20px;

      .error {
        margin-top: 18px;
        color: $validation-red;
      }

      .customTextarea {
        width: 100%;
        height: 120px;
        padding: 16px 24px;
        box-sizing: border-box;
        resize: none;
        outline: none;
        border: none;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 32px;
        color: $new-white;
        font-family: "Plus Jakarta Sans", sans-serif;

        &.error {
          border: 1px solid $validation-red;
        }

        &::placeholder {
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    .footerContainer {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;

      @media (max-width: 400px) {
        gap: 30px;
      }
    }


    //.headerWrapper {
    //  padding-bottom: 15px;
    //  padding-top: 7px;
    //  .header {
    //    text-align: center;
    //    color: #F2F5FA;
    //    font-size: 18px;
    //    font-weight: 500;
    //    letter-spacing: 0.3px;
    //
    //  }
    //}
    //
    //
    //.alertHeaderWrapper {
    //  //padding-bottom: 18px;
    //
    //  .header {
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    svg {
    //      //width: 37px;
    //      //height: 36px;
    //    }
    //  }
    //
    //  .content {
    //    text-align: center;
    //    color: #F2F5FA;
    //    font-size: 18px;
    //    font-weight: 500;
    //    letter-spacing: 0.3px;
    //    padding-top: 7px;
    //    padding-bottom: 15px;
    //    //display: flex;
    //    //justify-content: center;
    //    //padding-bottom: 10px;
    //
    //  }
    //}
    //
    //.popupContent {
    //  background-color: #2A3447;
    //  border-radius: 12px;
    //  //padding: 24px;
    //  padding: 25px 15px 15px;
    //
    //
    //  //.textInput {
    //  //  border-radius: 8px;
    //  //  border: 1px solid #5A6A85;
    //  //  background-color: #232D3F;
    //  //  padding: 14px 12px;
    //  //  width: 100%;
    //  //  box-sizing: border-box;
    //  //
    //  //  color: #F2F5FA;
    //  //
    //  //  &::placeholder {
    //  //    color: #7C8FAC;
    //  //    font-size: 14px;
    //  //    font-weight: 400;
    //  //    line-height: 140%; /* 19.6px */
    //  //    letter-spacing: 0.5px;
    //  //  }
    //  //}
    //
    //
    //  .buttonsWrapper {
    //    margin-top: 30px;
    //    display: flex;
    //    justify-content: space-between;
    //    align-items: center;
    //  }
    //
    //  //.buttonsWrapper {
    //  //  display: flex;
    //  //  margin-top: 20px;
    //  //  justify-content: space-between;
    //  //  align-items: center;
    //  //
    //  //  .cancelBtn {
    //  //    box-sizing: border-box;
    //  //    height: 36px;
    //  //    border-radius: 4px;
    //  //    border: 1px solid #5D87FF;
    //  //    color: #5D87FF;
    //  //    padding: 0 20px;
    //  //    font-size: 14px;
    //  //    line-height: 140%;
    //  //    letter-spacing: -0.28px;
    //  //
    //  //    transition: 0.3s ease;
    //  //
    //  //
    //  //    &:hover {
    //  //      border-color: #01C0C8;
    //  //      color: #01C0C8;
    //  //    }
    //  //  }
    //  //
    //  //  .createBtn {
    //  //    border-radius: 4px;
    //  //    background: #5D87FF;
    //  //    box-sizing: border-box;
    //  //    height: 36px;
    //  //    padding: 0 20px;
    //  //
    //  //    color: #F2F5FA;
    //  //
    //  //    font-size: 14px;
    //  //    font-weight: 600;
    //  //    line-height: 140%;
    //  //    letter-spacing: -0.14px;
    //  //
    //  //    transition: 0.3s ease;
    //  //    //border: 1px solid #5D87FF;
    //  //
    //  //    &:hover {
    //  //      background-color: #01C0C8;
    //  //      color: #F2F5FA;
    //  //    }
    //  //  }
    //  //}
    //}

  }
}