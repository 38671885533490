@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";


.popupWrapper {
  @include popup-wrapper;

  .popup {
    @include popup;
    max-width: 695px;

    p {
      margin: 6px 0;

      @media (max-width: 900px) {
        margin: 3px 0;
        font-size: 14px;
      }
    }

    .header {
      padding: 20px;
      display: flex;
      gap: 16px;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      @media (max-width: 900px) {
        padding: 10px;
      }

      .closePopup {
        top: 20px;
      }

      .icon {
        width: 40px;
        height: 40px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        padding: 10px;
        box-sizing: border-box;
        display: inline-flex;

        svg path {
          stroke: $new-white;
        }
      }

      .popupInfo {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .title {
          //letter-spacing: .5px;
          font-weight: 600;
          text-align: start;
          color: $new-white;

          @media (max-width: 900px) {
            font-size: 14px;
          }
        }

        .description {
          color: #CACFD8;
          text-align: start;
          white-space: normal;
          word-wrap: break-word;
          overflow-wrap: break-word;
          line-height: 20px;

          span {
            color: $red;
            margin-right: 3px;
          }

          a {
            color: $primary-blue;
          }

          @media (max-width: 900px) {
            font-size: 14px;
          }
        }
      }


    }

    .body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (max-width: 900px) {
        gap: 10px;
        padding: 20px;
      }

      .redirectorInputWrapper {
        position: relative;
        display: flex;
        align-items: flex-end;

        .selectWrapper {
          width: calc(100% - 180px);

          @media (max-width: 520px) {
            width: calc(100% - 95px);
          }

        }

        .applicationsWrapper {
          margin-left: .3px;
          border-left: 1px solid #525866;
          position: relative;
          //position: absolute;
          height: 48px;
          border-radius: 0 40px 40px 0;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.1);
          width: 205px;
          padding: 0 10px 0 13px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 520px) {
            width: 95px;
          }


          .applicationsPercentagesPopup {
            position: absolute;
            top: 100%;
            //width: 100%;
            //min-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            border-radius: 8px;
            box-sizing: border-box;
            padding: 6px;
            z-index: 20;
            max-height: 250px;
            overflow-y: auto;
            width: 195px;
            //overflow-x: hidden;
            //right: 100%;
            right: 0;
            @include thin-scrollbar(3px);

            //background: $sidebar-bg;
            //justify-content: ;
            background: rgba(35, 38, 44, 1);
            //background: rgba(255, 255, 255, 1);

            .item {
              display: flex;
              align-items: center;
              gap: 4px;
              flex-grow: 1;
              width: 100%;

              .iconWrapper {
                //flex-shrink: 0; /* Запобігання стисненню контейнера для зображення */
                //flex: 0 0 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                flex: 0 0 auto;
              }

              .appNameWrapper {
                min-width: 115px;
                overflow: hidden;
                flex-grow: 1;
                display: inline-flex;

                text-overflow: ellipsis;
                white-space: nowrap;

                span {
                  text-align: start;
                    left: 0;
                    height: 100%;
                    width: 100%;
                  right: 0;
                    top: 0;
                    bottom: 0;
                }
              }

              .appName {
                flex-grow: 1;
                width: 100%;
                text-align: start;
                font-size: 12px;
                min-width: 115px;
                position: relative;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                //z-index: 30;
                //
                //
                //&:hover {
                //  overflow: initial;
                //  text-overflow: initial;
                //}

                cursor: text;

                //span {
                //  left: 0;
                //  height: 100%;
                //  width: 100%;
                //  top: 0;
                //  bottom: 0;
                //}

                button {
                  cursor: text !important;
                }
              }

              .percentage {
                color: $light-grey-font;
                font-size: 12px;
              }
            }
          }


          .applicationsContainer {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            justify-content: center;
            align-items: center;
            text-align: center;

            //&::after {
            //  content: '...'; /* Три крапки */
            //  position: absolute;
            //  right: 15px;
            //  //top: 10px;
            //  bottom: 11.5px;
            //  letter-spacing: 1px;
            //  font-size: 19px;
            //  //padding-left: 10px;
            //  //background: rgba(255, 255, 255, 0.1);
            //  background: inherit;
            //
            //
            //  //background-color: #171a21; /* Колір фону */
            //  color: red; /* Колір еліпсису */
            //}
          }

          .appValue {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            margin-right: 5px;
            font-size: 12px;
            color: $light-grey-font;

            .iconWrapper {
              //flex-shrink: 0; /* Запобігання стисненню контейнера для зображення */
              //flex: 0 0 0;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }

            .mockedIcon {
              border-radius: 2px;
              width: 20px;
              height: 15px;
              background: $new-white;
            }
          }


        }
      }

      .trafficMessage {
        font-size: 12px;
        font-weight: 300;
        text-align: start;
        line-height: 15px;
      }


      .postbackTitle {
        text-align: start;
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        position: relative;
        padding-bottom: 10px;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #e0e1e2 49.52%, rgba(224, 225, 226, 0.15625) 99.04%);
        }
      }


      .inputName {
        color: $new-white;
        text-align: start;
        margin-bottom: 10px;

        @media (max-width: 900px) {
          font-size: 14px;
        }
      }
    }
  }
}
