@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins.scss";

body {
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  background: $main-components-bg;
  font-family: "Ubuntu", sans-serif;
  width: 100%;

  &::-webkit-scrollbar {
    background: $scroll-bg;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scroll-stick;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  button {
    cursor: pointer;
    text-decoration: none;
    border: none;
    text-align: center;
    padding: 0;
    margin: 0;
    background: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li {
    padding: 0;
    margin: 0;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: black;
  }
}
