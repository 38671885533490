@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include page-marking;
@include primary-button;
@include stats-table;
@include stats-skeleton;
@include page-content;
@include table-actions-wrapper;
@include base-text-input(#171a21);
@include form-buttons;
@include one-row-header;
@include switch-content-border-bottom;
@include form-fields-wrapper;
@include page-inner-content;


.flowSettingsWrapper {
  position: relative;
  margin-bottom: 90px;

  @media (max-width: 1000px) {
    margin-bottom: 0;
  }

  .pageHeaderWrapper {
    z-index: 999 !important;
  }

  .pageMarking {
    white-space: nowrap;
    overflow: auto;
    padding-right: 30px;

    @include invisible-scrollbar;
  }

  .switchPageContentContainer {
    margin-top: 20px;
  }
}