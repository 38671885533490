@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.adaptiveWheelPreviewBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background: #E1E7FF;
  box-shadow: 0 0 17.4px 4px #98ADFE80;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media (min-width: 1001px) {
    display: none;
  }

  .text {
    color: $primary-blue;
    font-size: 14px;
  }
}