@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";


.popupWrapper {
  @include popup-wrapper;

  .popup {
    @include popup;
    max-width: 580px;

    p {
      margin: 0;
    }

    .bodyContainer {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        //padding: 10px;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        //display: inline-block;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
      }

      .title {
        //letter-spacing: 1px;
        font-weight: 600;
        color: $new-white;
        text-align: center;
      }


      .description,
      .subDescription {
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        line-height: 20px;
        text-align: center;
      }

      .description {
        color: $new-white;
      }

      .subDescription {
        color: #CACFD8;
        font-size: 14px;
      }
    }

    .footer {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;

      @media (max-width: 400px) {
        gap: 30px;
      }
    }
  }
}
