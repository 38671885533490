@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.mainInputWrapper {
  position: relative;
  @include base-text-input;

  .textInput {
    border: none;
  }

  .copyRedirectContainer {
    position: absolute;
    top: 0;
    right: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .copyBtn {
      cursor: pointer;
      height: 44px;
      box-sizing: border-box;

      svg {
        cursor: pointer;
      }
    }

    .followLink {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding-right: 5px;

      svg {
        width: 17px;
        height: 17px;

        path {
          stroke: $primary-blue;
        }
      }
    }
  }

  .copyIconContainer {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .copyBtn {
      height: 44px;
      width: 44px;

      svg path {
        stroke: $new-white;
      }

      &:hover {
        svg path {
          stroke: $primary-blue;
        }
      }
    }
  }
}