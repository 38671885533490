.gradientBorderContainer {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #e0e1e2 49.52%, rgba(224, 225, 226, 0.15625) 99.04%);
  }
}