@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

@include table-actions-wrapper;

.popupWrapper {
  @include popup-wrapper;
  @include base-text-input;

  .popup {
    @include popup;
    max-width: 700px;

    p {
      margin: 6px 0;

      //@media (max-width: 900px) {
      //  margin: 3px 0;
      //  font-size: 14px;
      //}
    }


    .header {
      padding: 20px;
      display: flex;
      gap: 16px;
      align-items: flex-start;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      //@media (max-width: 900px) {
      //  padding: 10px;
      //}

      .icon {
        //width: 40px;
        //height: 40px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        padding: 10px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        display: inline-flex;

        svg {
          //width: 24px;
          //height: 24px;

          path {
            //stroke: $new-white;
          }
        }
      }

      .popupInfo {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .title {
          //letter-spacing: 1px;
          font-weight: 600;
          text-align: start;
          color: $new-white;

          @media (max-width: 900px) {
            font-size: 14px;
          }
        }
        .description {
          color: $darkInputPlaceholderGrey;
          text-align: start;
          white-space: normal;
          word-wrap: break-word;
          overflow-wrap: break-word;
          line-height: 15px;
          font-size: 12px;
          font-weight: 300;

          //@media (max-width: 900px) {
          //  font-size: 14px;
          //}
        }

      }
    }

    .body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      box-sizing: border-box;
      //height: 300px;
      height: 500px;
      overflow-y: auto;
      position: relative;

      @media (min-width: 2100px) {
        height: 470px;
      }

      @media (max-width: 1500px) {
        height: 400px;
      }

      @media (max-width: 1300px) {
        height: 400px;
      }

      @media (max-width: 1000px) {
        height: 450px;
      }

      .error {
        margin-top: 5px;
        color: $validation-red;
      }

      .applicationsTitleContainer {
        margin-bottom: 15px;
        .gradientBorderCustom {
          height: 30px;
        }

        .applicationsTitle {
          color: #FFFFFF80;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .applicationsContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .applicationsWrapper {
          display: flex;
          flex-direction: column;
          gap: 25px;

          .applicationOptionWrapper {
            display: flex;
            gap: 30px;
            align-items: start; /* Вирівнювання зверху */
            //position: relative;
            flex-wrap: wrap;

            .selectWrapper {
              grid-column: 1;
              flex: 1 0 auto;
              min-width: 350px;
              position: relative;

              @media (max-width: 500px) {
                min-width: 300px;
              }
            }

            .wrapErrorContainer {
              display: flex;
              flex-direction: column;
            }

            .wrapContainer {
              display: flex;
              align-items: flex-start;
              gap: 30px;
              //flex: 1 0 0;
              //justify-content: flex-end;
              width: 260px;
            }

            .percentageWrapper {
              //grid-column: 2; /* Друга колонка для блоку з відсотками */
              display: flex;
              flex-direction: column;
              flex: 0 0 auto;
              align-items: flex-start; /* Вирівнюємо елементи по лівому краю */
              //min-width: 150px; /* Фіксована мінімальна ширина для блоку з відсотками */
              //width: 100%; /* Забезпечує динамічне розтягування при довгих повідомленнях */
            }

            .mockContainer {
              //grid-column: 3;
              width: 60px;
            }

            .actionsWrapper {
              //grid-column: 3; /* Третя колонка для дій */
              display: flex;
              align-items: center;
              justify-content: flex-end;
              position: relative;
              z-index: 3;
              border-radius: 35px;
              margin-top: 6px;

              .actions {
                background: linear-gradient(
                                117.45deg,
                                rgba(255, 255, 255, 0) -3.91%,
                                rgba(255, 255, 255, 0.039) 75.27%
                );
                padding: 0;
                .removeBtn {
                  width: 60px;
                  padding: 3px;
                }

              }

              .actionsContainer {
                background: $sidebar-bg;
              }
            }

            .errorMessage {
              color: $validation-error;
              font-size: 15px;
              margin-top: 5px;
              text-align: left; /* Вирівнювання тексту по лівому краю */
              min-height: 16px;
              width: 100%; /* Додаємо ширину 100% для контейнера */
              white-space: nowrap;
              //white-space: normal; /* Дозволяє переносити текст на наступний рядок */
              //word-wrap: break-word; /* Переносить довгі слова */
            }
          }
        }

        .errorMessageWrapper {
          text-align: end;

          .errorMessage {
            color: $validation-error;
            font-size: 15px;
            margin-top: 5px;
            text-align: left; /* Вирівнювання тексту по лівому краю */
            min-height: 16px;
            width: 100%; /* Додаємо ширину 100% для контейнера */
            white-space: nowrap;
            //white-space: normal; /* Дозволяє переносити текст на наступний рядок */
            //word-wrap: break-word; /* Переносить довгі слова */
          }
        }
      }


      .distributePercentageContainer {
        display: flex;
        justify-content: flex-start;
        margin: 20px 0;
        gap: 20px;
        flex-wrap: wrap;

        @include primary-button;

        .primaryBtn {
          font-size: 13px;
        }
      }

      .addApplicationContainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        @include primary-button;

        .primaryBtn {
          font-size: 13px;
        }
      }

      @include scrollbar;

      //@media (max-width: 900px) {
      //  gap: 10px;
      //  padding: 20px;
      //}

      .inputName {
        color: $new-white;
        text-align: start;
        margin-bottom: 10px;

        @media (max-width: 900px) {
          font-size: 14px;
        }
      }
    }

    .footerContainer {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 20px;

      @media (max-width: 400px) {
        gap: 30px;
      }
    }
  }
}
