@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include page-marking;
@include primary-button;
@include stats-table;
@include stats-skeleton;
@include page-content;
@include page-header-wrapper;
@include table-actions-wrapper;
@include base-text-input(#171a21);
@include form-buttons;
@include one-row-header;
@include switch-content-border-bottom;
@include form-fields-wrapper;
@include form-buttons;

.createFlowWrapper {
  position: relative;

  .support {
    text-align: center;
    a {
      margin-left: 5px;
      color: $primary-blue;
    }
  }

  .switchPageContentContainer {

    .pageBtn {
      border-bottom: 2px solid $sidebar-bg;

      &.hardCoded {
        border-bottom: 2px solid $primary-blue;
        transform: scale(1.05);

        svg {
          path {
            fill: $primary-blue;
          }
        }
      }


      &.disabled {
        color: #717784;
        cursor: default;
      }
    }
  }

  .domainInfoWrapper {
    .domainInfo {
      padding: 12px 40px;
      display: inline-flex;
      align-items: center;
      border-radius: 35px;
      gap: 12px;
      font-size: 12px;
      color: $new-white;
      background: #2D313C;
      position: relative;
      @include border-before-gradient;

      @media (max-width: 620px) {
        display: flex;
        padding: 12px 15px;
        line-height: 17px;
      }

      @media (max-width: 430px) {
        display: flex;
        padding: 6px 15px;
        line-height: 17px;
      }
    }
  }
  
  .form {
    margin-top: 30px;
    .formFieldsWrapper {
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;
      column-gap: 40px;

      @media (max-width: 1000px) {
        flex-direction: column;
      }

      .fieldWrapper {
        flex: 1 0 0;

        .fieldName {
          span {
            color: $light-grey-font;
          }
        }
      }
    }
  }

  .pageMarking {
    margin-bottom: 22px;
  }

  &.collapsedSidebar {
    @include page-header-wrapper-collapsed;
  }
}