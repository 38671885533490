@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.footerWrapper {
  background: $sidebar-bg;
  margin-top: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 224px;
  z-index: 999;
  box-sizing: border-box;
  display: flex;
  column-gap: 30px;
  row-gap: 30px;
  padding: 10px 32px;
  height: 51px;
  align-items: center;
  font-family: Ubuntu, sans-serif;
  flex-wrap: wrap;

  &.collapsedSidebar {
    left: 94px;
  }


  @media (max-width: 1000px) {
    left: 0;
    padding: 10px 15px;
    position: static;
  }

  @media (max-width: 810px) {
    column-gap: 100px;
    height: 100%;
    justify-content: space-between;

    .infoWrapper {
      column-gap: 100px;
      justify-content: space-between;
    }
  }


  .infoWrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    column-gap: 300px;
    flex-wrap: wrap;
    row-gap: 30px;
  }

  .logoWrapper {
    svg {
      width: 47px;
      height: 16px;
    }
  }

  .socials {
    display: flex;
    gap: 30px;
    margin-top: 4px;
    align-items: center;
    justify-content: center;

    .socialLink {
      svg {
        width: 24px;
        height: 24px;
      }
    }

  }

  .servicesLinks {
    color: $new-white;
    display: flex;
    gap: 200px;
    flex-wrap: nowrap;

    p {
      margin: 0;
    }

    .linkDetails {
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 6px;

      .linkTitle {
        color: $new-dark-grey-font;
        font-size: 12px;
        line-height: 10px;
      }

      .pwaLink {
        line-height: 10px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: .7px;
      }

      .pwaLink,
      .privacyPolicyLink {
        color: $new-white;
      }
    }
  }

  .companyInfo {
    color: $new-white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;

    .rights,
    .name {
      margin: 0;
      line-height: 10px;
    }

    .rights {
      font-weight: 400;
      font-size: 12px;
    }

    .name {
      font-family: Druk Wide Cyr, sans-serif;
      font-weight: 800;
      font-size: 12px;
    }
  }
}