@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.languageDropdownWrapper {
  display: flex;
  align-items: center;
  position: relative;

  .selectedLang {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: $darkInputPlaceholderGrey;
    gap: 10px;
    text-transform: uppercase;

    svg {
      width: 15px !important;
      height: 8px !important;
    }
  }

  .langList {
    position: absolute;
    background: $sidebar-bg;
    width: 88px;
    top: calc(100% + 8px);
    display: flex;
    flex-direction: column;
    padding: 8px;
    box-sizing: border-box;
    z-index: 500;
    font-weight: 400;
    font-size: 16px;
    border-radius: 25px;
    gap: 5px;
    overflow: hidden;
    pointer-events: none;
    transition: max-height .3s ease, opacity .2s ease .07s;
    opacity: 0;
    max-height: 0;

    &.active {
      max-height: 133.98px;
      opacity: 1;
      pointer-events: auto;
      transition: max-height .3s ease, opacity .3s ease;
    }

    .langOptionBtn {
      height: 36px;
      box-sizing: border-box;
      padding: 8px 12px 8px 16px;
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 20px;
      font-weight: 500;
      font-size: 14px;
      color: #F2F5FA;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      justify-content: space-between;

      &:hover {
        background: #1E2437;
      }

      svg {
        width: 13px !important;
        height: 11px !important;
      }
    }
  }
}