@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include blue-border-button;
@include primary-button;
@include page-content;
@include filters-wrapper;
@include stats-skeleton;
@include stats-table;
@include table-actions-wrapper;
@include page-inner-content;

.pixelWrapper {
  position: relative;

  .filters {

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .selectWrapper {
      @media (max-width: 600px) {
        width: 100%;
      }
    }

    &.oneFilter {
      justify-content: flex-end;

      .selectWrapper {
        position: relative;
        min-width: 280px;
        flex: 0 1 auto;
        width: auto;
        box-sizing: border-box;

        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }

    @media (max-width: 600px) {
      .cleanBtnContainer {
        margin: 0;

      }
    }

    @media (max-width: 400px) {
      .cleanBtnContainer {
        flex: 1 1 auto;
        width: 100%;
      }
    }
  }

  .tokenTd {
    display: flex;
    justify-content: center;
    align-items: center;


    .tokenLink {
      display: flex;
      align-items: center;
    }

    .token {
      max-width: 180px;
      text-align: center;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      color: $new-white;
      //display: flex;
    }
  }

  td {
    padding: 0 15px;

    .falseStatus {
      color: $validation-red;
    }

    .trueStatus {
      color: $validation-green;
    }
  }
}