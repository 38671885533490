@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include page-marking;
@include primary-button;
@include stats-table;
@include stats-skeleton;
@include page-content;
@include table-actions-wrapper;
@include base-text-input(#171a21);
@include form-buttons;
@include one-row-header;
@include content-buttons-wrapper;

.createPrelandingWrapper {
  position: relative;

  .pageHeaderWrapper {
    z-index: 999 !important;
  }

  @media (min-width: 1000px) {
    margin-bottom: 80px;
  }


  &.collapsedSidebar {
    .sectorsWrapper {

      @media (max-width: 1650px) {
        grid-template-columns: repeat(2, 1fr) !important;
      }

      @media (max-width: 1490px) {
        grid-template-columns: 1fr !important;
      }
    }
  }



  .wheelSkeleton {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .gradientBorderCustom {
    height: 40px;
  }
}