@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include page-marking;
@include primary-button;
@include stats-table;
@include stats-skeleton;
@include page-content;
@include page-header-wrapper;
@include table-actions-wrapper;
@include base-text-input(#171a21);
@include form-buttons;
@include preland-elements;

.createPrelandingForm {
  .formContentWrapper {
    display: flex;
    gap: 50px;
    position: relative;

    @media (max-width: 1260px) {
      flex-direction: column;
    }
  }

  .buttons {
    margin-top: 50px;
  }

  .formFieldsWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    gap: 20px;

    .uniqueSettingsTitle {
      display: flex;
      align-items: center;

      span {
        color: $new-white;
        font-size: 12px;
        margin-left: 8px;
      }
    }

    .fieldWrapper {
      p {
        margin: 6px 0;
      }

      .fieldName {
        font-size: 14px;
        color: $new-white;
      }

      .error {
        margin-top: 8px;
        color: $validation-error;
      }
    }
  }
}