@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include page-marking;
@include primary-button;
@include stats-table;
@include stats-skeleton;
@include page-content;
@include page-header-wrapper;
@include table-actions-wrapper;
@include base-text-input(#171a21);
@include form-buttons;
@include one-row-header;
@include switch-content-border-bottom;
@include form-fields-wrapper;

.whitePageForm {



  @include page-inner-content;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  //height: 500px;
  flex-grow: 1;

  .textInput {
    padding-right: 15px;

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .rowtitle {
    color: $new-white;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 5px 0 12px 0;
  }

  .disabledBlockDescription {
    color: $light-grey-font;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 17px 0 13px 0;

    svg {
      flex: 0 0 auto;
    }
  }

  .fieldsWrapper {
    width: 100%;
    padding: 0 1px 20px 1px;
    display: flex;
    flex-direction: column;


    .cloacaModeTooltipWrapper {
      background: #0E121B;
      border-radius: 8px;
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 6px;
      gap: 5px;
      z-index: 10;
      box-sizing: border-box;
      bottom: calc(100% - 25px);
      left: 190px;
      //align-items: center;
      max-width: 330px;
      width: 100%;

      span {
        font-weight: 600;
      }

      @media (max-width: 570px) {
        left: 0;
        bottom: 100%;
      }

      @media (max-width: 420px) {
        max-width: 100% ;
      }
      
      p {
        line-height: 17px;
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        color: $new-white;
        margin: 0 !important;
      }
    }

    .fieldWrapper {
      flex: 1 0 0;
      position: relative;

      p {
        margin: 6px 0;
      }

      .tipMessage {
        margin: 6px 0 0 0;
      }

      .fieldName,
      .tipMessage {
        display: flex;
        align-items: center;
        gap: 6px;
        //margin: 6px 0 0 0;

        font-size: 14px;
        color: $new-white;

        span {
          color: $light-grey-font;
        }
      }

      .error {
        font-size: 14px;
        margin-top: 8px;
        color: $validation-error;
      }
    }


    .fieldColumn {
      flex-direction: column;
    }

    .fieldRow,
    .fieldColumn {
      display: flex;
      //flex-direction: column;
      gap: 20px;
      flex: 1 1 auto;

      @media (max-width: 1380px) {
        flex-direction: column;
        gap: 20px;
      }

    }


    .fieldsColumn {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      gap: 30px;

      @media (max-width: 1300px) {
        gap: 45px;
      }

      .rowsWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

  }


}