@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.sidebarContainer {
  @media (max-width: 1000px) {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: 0.4s ease-in-out;
    pointer-events: none;

    &.closedMenu {
      pointer-events: none;
      backdrop-filter: none;
      background-color: transparent;

      .sidebarWrapper {
        left: -270px;
        transition: 0.4s ease-in-out;
      }
    }

    &.activeMenu {
      pointer-events: auto;
    }
  }
  }

.sidebarWrapper {
  background: $sidebar-bg;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 224px;
  min-height: 100vh;
  height: 100%;
  position: fixed;

  transition: left 0.4s ease-in-out;

  &.activeMenu {
    .sidebarWidthBtn {
      display: none;
    }
  }

  &.closedMenu {
    left: -100%;
  }

  &.collapsed {
    width: 94px;


    .returnToAdminContainer {
      .returnToAdminButton {
        .btnDesc {
          display: none;
        }
      }
    }

    .sidebarHeader {
      min-height: 64px !important;
      justify-content: center;


      .info {
        display: none;
      }
    }

    .sidebar {
      .chapterWrapper {
        .chapterTitle {
          text-align: center;
        }

        .menu {
          .menuBtnWrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .link {
              justify-content: center;
              gap: 0;
              width: 50px;
              height: 50px;
              box-sizing: border-box;

              .pageTitle {
                display: none;
              }
            }
          }
        }
      }
    }
    .menuBtnWrapper {
      .link {
        .alertContainer {
          position: absolute;
          right: 0;
          top: -5px;
        }
      }
    }
  }

  .gradientBorderCustom {
    margin-bottom: 10px;
  }

  .sidebarHeader {
    padding: 12px 20px 12px 12px;
    display: flex;
    box-sizing: border-box;
    min-height: 64px;
    justify-content: space-between;
    align-items: center;

    .info {
      display: flex;
      gap: 6px;
      flex-direction: column;
      flex: 1 1 auto;

      .balanceInfoContainer {
        display: flex;
        align-items: center;

        .userIconContainer {
          width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .balanceBtn {
          font-weight: 600;
          color: $new-white;
          font-size: 14px;
          margin-left: 5px;
          max-width: 125px;
          letter-spacing: .4px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .plus {
            margin-left: 3px;
            color: $primary-blue;
          }
        }
      }

      .userInfoContainer {
        display: flex;
        flex: 1 1 auto;

        .email {
          color: $light-grey-font;
          font-size: 14px;
          max-width: 150px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
          margin: 0;

          @media (max-width: 1000px) {
            max-width: 180px;
          }

          button {
            position: absolute;
            cursor: text;
            width: 150px;
            left: 0;
          }
        }
      }
    }

    .changeWidthBtn {
      background: rgba(93, 135, 255, 0.1);
      width: 30px;
      height: 30px;
      flex: 0 0 auto;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(180deg);

      @media (max-width: 1000px) {
        display: none;
      }

      &.rotated {
        transform: rotate(360deg);
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .gradientContainer {
    padding: 0 20px 0 12px;
  }

  .returnToAdminContainer {
    margin-bottom: 10px;
    padding: 0 20px 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    .returnToAdminButton {
      display: flex;
      align-items: center;
      justify-content: center;

      .returnIconContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        overflow: hidden;
        //width: 100%;
        box-sizing: border-box;
        white-space: nowrap;
        padding: 7px;
        font-family: Ubuntu, sans-serif;
        text-align: start;
        height: 50px;
        border-radius: 15px;

        svg {
          width: 20px;
          height: 20px;
          border-radius: 15px;
          flex: 0 0 auto;
          padding: 6px;
          background: $sidebar-active-bg;
        }
      }

      .btnDesc {
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: flex-start;
        width: 138px;

        span {
          font-size: 13px;
          color: $new-white;
          text-align: start;
        }

        .email {
          color: $light-grey-font;
          max-width: 138px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .menuBtnWrapper {
    cursor: pointer;
    width: 100%;
    position: relative;

    .link {
      display: flex;
      align-items: center;
      gap: 10px;
      overflow: hidden;
      width: 100%;
      box-sizing: border-box;
      white-space: nowrap;
      padding: 7px;
      font-family: Ubuntu, sans-serif;
      text-align: start;
      height: 50px;

      .alertContainer {
        position: absolute;
        right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          background: none !important;
          width: 17px;
          height: 17px;
          padding: 0;
        }
      }

      .pageTitle {
        color: $new-white;
        flex: 1 1 auto;
        font-size: 13px;
        letter-spacing: .3px;
      }

      svg {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        flex: 0 0 auto;
        padding: 6px;
        background: $sidebar-active-bg;
      }

      border-radius: 15px;

      &.active {
        background: $sidebar-active-bg;

        svg {
          background: $new-blue !important;
        }

        .alertContainer {
          svg {
            background: none !important;
          }
        }
      }

      &:hover {
        transition: 0.3s;
        background: $sidebar-active-bg;

        svg {
          background: none;
        }
      }

      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
    }
  }

  .sidebar {
    z-index: 100;
    padding: 0 20px 0 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(99%);
    flex-grow: 1;
    direction: rtl;

    & > * {
      direction: ltr;
    }


    //@include scrollbar(3px);


    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {

      //background: rgba(255, 255, 255, 0.2);
      background: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      //background: rgba(255, 255, 255, 0.1);
      background: transparent;
      border-radius: 10px;
    }


    &:hover {

      @include scrollbar(3px);

      //&::-webkit-scrollbar-thumb {
      //  background: rgba(255, 255, 255, 0.2);
      //  border-radius: 10px;
      //}
      //
      //&::-webkit-scrollbar-track {
      //  background: rgba(255, 255, 255, 0.1);
      //  border-radius: 10px;
      //}
    }

    @media (max-width: 1000px) {
      overflow-y: auto;
    }

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    @media (max-width: 1000px) {
      padding-bottom: 190px;
    }

    .aLinks {
      display: flex;
      margin-top: 14px;
      gap: 14px;
      flex-direction: column;
      margin-bottom: 30px;
    }

    .chapterWrapper {
      .chapterTitle {
        color: $light-grey-font;
        text-transform: uppercase;
        margin-bottom: 20px;
        margin-top: 15px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: .5px;
      }
      
      .menu {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      
      &:nth-child(2) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .redirectLink {
      transition: 0.3s;
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 10px 0 10px 7px;
      font-size: clamp(10px, 13px, 20px);
      color: #f2f5fa;

      &:hover {
        transition: 0.3s;
        color: #5d87ff;

        svg path {
          stroke: #5d87ff;
        }
      }
    }
  }
}
