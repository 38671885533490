@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";


.popupWrapper {
  @include popup-wrapper;

  .popup {
    @include popup;
    max-width: 580px;

    p {
      margin: 6px 0;
    }

    .header {
      padding: 20px;
      display: flex;
      gap: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      .icon {
        width: 40px;
        height: 40px;
        flex: 0 0 auto;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        //padding: 10px;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        svg {

        }
      }

      .popupInfo {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .title {
          //letter-spacing: 1px;
          font-weight: 600;
          color: $new-white;
        }

        .description {
          color: #CACFD8;
          white-space: normal;
          word-wrap: break-word;
          overflow-wrap: break-word;
          line-height: 20px;

          a {
            color: $primary-blue;
          }
        }
      }


    }

    .body {
      padding: 20px;

      .inputDesc {
        color: $new-white;
        margin-bottom: 10px;
      }
    }
  }
}
