@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.authWrapper {

  padding: 75px 25px;
  background: #0e121b;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  //flex-direction: column;
  //justify-content: space-between;
  //gap: 40px;
  //align-items: center;
  min-height: 100vh;
  flex: 1 1 auto;



  @media (max-width: 1000px) {
    height: 100dvh;
    min-height: auto;
    padding: 75px 20px;
  }

  //@media (max-width: 800px) {
  //  min-height: 100dvh;
  //  height: 100%;
  //}


  .authContentWrapper {
    //width: 100%;
    display: flex;
    flex-direction: column;
    //align-items: center;
    z-index: 20;
    gap: 40px;
    //height: 100%;
    min-height: calc(100vh - 150px);

    @media (max-width: 600px) {
      width: 100%;
    }


    .contentContainer {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 80px;

      @media (max-width: 1000px) {
        //gap: 0;
      }


      .iphonesImg {
        pointer-events: none;
        user-drag: none; /* Для Safari та Chrome */
        user-select: none; /* Заборона виділення */
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;;
          

        @media (min-width: 1900px) {
          width: 385.5px;
          height: 781px;
        }

        @media (min-width: 1700px) and (max-width: 1900px) {
          width: 350px;
          height: 700px;
        }

        @media (max-width: 1700px) {
          width: 280px;
          height: 560px;
        }

        @media (max-width: 1000px) {
          display: none;
        }
      }
    }
  }



  .bgImgBL {
    position: absolute;
    //width: 1000px;
    //height: 800px;
    width: 1000px;
    height: 990px;
    left: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    //transform: rotate(180deg);
    @media (max-width: 1000px) {
      bottom: -300px;
      left: -300px;
    }
  }

  .bgImgTR {
    position: absolute;
    //width: 1800px;
    //height: 1400px;
    //right: -200px;
    width: 1180px;
    height: 900px;
    right: 0;
    top: 0;
    //top: -600px;
    //transform: rotate(180deg);
    pointer-events: none;
    z-index: 1;

    @media (max-width: 1000px) {
      top: -200px;
      right: -200px;
    }

  }

  .companyRightsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808192;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 300;

  }

}











//@import 'src/assets/styles/variables';
//@import 'src/assets/styles/mixins';
//
//.authWrapper {
//
//  padding: 20px 25px;
//  background: #0e121b;
//  //background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
//  overflow: hidden;
//  position: relative;
//  box-sizing: border-box;
//  font-family: "Open Sans", sans-serif;
//  display: flex;
//  flex-direction: column;
//  justify-content: space-between;
//  gap: 40px;
//  align-items: center;
//  min-height: 100vh;
//  flex: 1 1 auto;
//
//  @media (max-width: 800px) {
//    min-height: 100dvh;
//    height: 100%;
//  }
//
//  .bgWrapper {
//    z-index: 10;
//    position: absolute;
//    top: 50%;
//    left: 50%;
//  }
//
//  .logoWrapper {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//
//    @media (max-width: 389px) {
//      svg {
//        width: 70px;
//      }
//    }
//  }
//
//  .companyRightsWrapper {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    color: $white;
//    white-space: nowrap;
//  }
//
//}