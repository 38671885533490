@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';



.prelandCardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prelandCard {
  width: 158px;
  border-radius: 30px;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid transparent;

  &.active {
    border: 2px solid $primary-blue;
  }

  .header {
    background: $sidebar-bg;
    height: 52px;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    padding: 12px 12px 12px 8px;
    align-items: center;

    &.custom {
      border-bottom: 2px solid $main-components-bg;
    }

    .editPrelandButton {
      button {
        padding: 0;
      }
    }

    .prelandName {
      color: $new-white;
      font-size: 12px;
      font-weight: 500;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-word;
      flex-grow: 1;
    }
  }



  .customBody {
    cursor: pointer;
    width: 100%;
    height: 334px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $sidebar-bg;

    img {
      max-width: 86px; /* Встановіть потрібну ширину */
      max-height: 76px; /* Встановіть потрібну висоту */
      object-fit: contain;
      margin-bottom: 35px;
    }
  }

  .body {
    cursor: pointer;
    width: 100%;
    height: 334px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;



    .title {
      color: $new-white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
    }

    .wheelWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .pointer {
        position: absolute;
        top: 0;
        z-index: 10;
        width: 20px;
        height: 30px;
      }

      .wheelImg {
        width: 150px;
      }
    }

    .btnWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        width: 90%;
      }

      span {
        z-index: 10;
        position: absolute;
        font-weight: 600;
        letter-spacing: 1.3px;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
        color: $new-white;
        font-size: 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .button {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      letter-spacing: 1.3px;
      text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
      color: $new-white;
    }
  }
}