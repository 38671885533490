

.spinWheel {
  transition: transform 4s ease-out;
  transform-origin: center; // Важливо для правильного центрування обертання

  &.spinning {
    animation: spin 4s linear infinite;
  }
}

.wheelContainer {
  //position: relative;
  position: sticky;
  top: 80px; /* Відстань від верхнього краю екрану */
  z-index: 100;
  height: 800px;

  .iphoneBorderWrapper {
    position: absolute;
    z-index: 600;
    clip-path: inset(0); /* При необхідності налаштуйте значення */
    pointer-events: none;
    //width: 445px;
    //width: 100%;
    left: -10px;
    width: 440px;
    height: 800px;

    //top: 0;
    //bottom: 0;
    //right: -20px;
    //left: -20px;
    //height: 805px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.prelandPreview {
  position: relative;
  width: 420px;
  overflow: hidden;
  //overflow-y: hidden;
  //flex-direction: column;
  height: 800px;
  //border: 12px solid #5a6a85;
  //border-radius: 66px;
  border-radius: 75px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  color: #F2F5FA;



  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .prelandLogo {
    //width: 100%;
    //height: 100%;
    margin-top: 10px;
    max-width: 200px;
    max-height: 150px;
  }

  .call {
    text-align: center;
    margin-top: 40px;
    font-weight: 700;
    font-size: 56px;
    font-family: Segoe UI, serif;
    //box-shadow: rgba(0, 0, 0, 1);
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  }

  .content {
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //position: absolute;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .pointer {
      z-index: 100;
      position: absolute;
      top: 0;
      //top: 0 !important;
    }

    .wheel {


      &.animated {
        @keyframes rotateAnimation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(1500deg);
          }
        }

        animation: rotateAnimation 4s ease-in-out;
      }
    }


    .nmb {
      position: absolute;
      color: red;
      top: 20%;
      left: 50%;
      transform: translate(10%, -50%) rotate(-70deg);
      transform-origin: center;
    }
  }


  .footer {
    .spinBtnWrapper {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;

      .spinBtn {
        z-index: 99;
        font-weight: 600;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background-size: cover; /* або 100% 100% для повного покриття */
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: none; /* Опціонально: видалити рамку кнопки */
        cursor: pointer; /* Опціонально: змінити курсор на покажчик */
        font-size: 30px;
        color: white; /* Колір тексту */
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);

      }
    }
  }

  .prelandPopup {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 110;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;


    .popupBg {
      z-index: 105;
      //height: 100%;
      //min-height: 310px;
      //min-width: 300px;
      //height: 100%;
      width: 90%;
      position: absolute;
    }

    .popupContent {
      //z-index: 109;
      //position: relative;
      //display: flex;
      //justify-content: center;
      //align-items: flex-end;


      height: 327px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0 10px;
      box-sizing: border-box;
      z-index: 109;
      width: 100%;

      .popupTextContent {

        //top: 30%;
        font-weight: 600;
        text-align: center;
        font-size: 28px;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
        //position: absolute;
        //top: 30%;
        //font-weight: 600;
        //font-size: 28px;
      }
      .popupTitle {

        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
        font-weight: 600;
        text-align: center;
        font-size: 23px;
        top: 10%;

        //font-weight: 600;
        //font-size: 23px;
        //position: absolute;
        //top: 10%;
      }

      .popupBtn {
        margin-bottom: 20px;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .btnBg {
          position: absolute;
          z-index: 10;
          width: 290px;
          //height: 85px;
        }

        //text-transform: uppercase;
        //position: absolute;
        //bottom: 5%;
        //cursor: pointer;


        .popupSpinBtn {
          z-index: 10;
          color: white;
          font-size: 30px;
          max-width: 290px;
          box-sizing: border-box;
          padding: 10px 40px;
          font-weight: 600;
          text-align: center;
          text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);


          @media(max-width: 431px) {
            font-size: 5vw;
          }
        }

        //.spinBtn {
        //    text-transform: uppercase;
        //    background-size: cover;
        //    background-repeat: no-repeat;
        //    background-position: center;
        //    display: flex;
        //    align-items: center;
        //    justify-content: center;
        //    text-align: center;
        //    border: none;
        //    cursor: pointer;
        //    color: white;
        //    font-size: 30px;
        //    margin-bottom: 15px;
        //}
        //
        //.spinBtnWrapper {
        //    cursor: pointer;
        //    position: relative;
        //    display: flex;
        //    justify-content: center;
        //    align-items: center;
        //    width: 310px;
        //    height: 100px;
        //    margin-bottom: 10px;
        //    img {
        //        z-index: 100;
        //    }
        //
        //    .btnContent {
        //        position: absolute;
        //        z-index: 150;
        //        font-size: 30px;
        //        font-weight: 600;
        //        text-transform: uppercase;
        //    }
        //}

      }
    }
  }
}