@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


.authFormWrapper {

  @include unauthorized-form;

  .welcomeBlock {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .formDescription {
    margin-top: 0;
  }

  .resetPassword {
    display: flex;
    justify-content: flex-end;

    a {
      color: $new-white;
      font-weight: 500;
      font-size: 14px;
    }
  }
}