@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include primary-button;
@include base-text-input(#171a21);
@include form-buttons;
@include form-fields-wrapper;

.uploadPrelandWrapper {

  .fieldWrapper {
    p {
      margin: 6px 0;
    }

    .fieldName {
      font-size: 14px;
      color: $new-white;
    }

    .error {
      margin-top: 8px;
      color: $validation-error;
    }
  }

  .uploadPrelandBtn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 10px 40px;
    border: 1px solid $primary-blue;
    box-sizing: border-box;
    color: $new-white;
    border-radius: 40px;
    cursor: pointer;

    &.zipArchiveError {
      border: 1px solid $validation-error;
    }
  }

}