@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

//@include content-buttons-wrapper;

.prelandingWrapper {
  //width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  //height: 500px;
  flex-grow: 1;

  .contentButtonsContainer {
    overflow-x: auto;
    overflow-y: hidden;
    @include invisible-scrollbar;
    display: inline-flex;
    width: 100%;
  }

  .contentButtonsWrapper {
    margin-top: 20px;
    border-radius: 31px;
    background: $sidebar-bg;
    align-items: center;
    display: flex;
    //display: inline-flex;
    padding: 6px 8px;
    gap: 20px;
    //flex: 0 0 auto;

    @media (max-width: 500px) {
      display: flex;
    }

    .contentBtn {
      height: 30px;
      box-sizing: border-box;
      width: 140px;
      flex: 0 0 auto;
      background: $main-components-bg;
      border-radius: 40px;
      color: $new-white;
      transition: all .2s ease;

      //@media (max-width: 500px) {
      //  width: 100%;
      //}

      &.active {
        transition: all .2s ease;
        color: $primary-blue;
        background: #1E2437;
      }
    }
  }

  .contentButtonsWrapper {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .gradientBorderCustom {
    margin-bottom: 20px;
  }
}