@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include primary-button;


.pageHeaderWrapper {
  z-index: 100;
  left: 0;
  right: 0;
  background-color: $main-components-bg;
  position: fixed;
  padding: 10px 0;
  box-sizing: border-box;
  min-height: 56px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &.collapsedSidebar {
    .pageHeaderContainer {
      padding-left: 124px;

      @media (max-width: 1000px) {
        padding: 0 15px;
      }
    }
  }

  .pageHeaderContainer {
    padding-left: 256px;
    padding-right: 32px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 20px;
      padding: 0 15px;
    }
  }

  .staticElements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;


    .leftSideWrapper {
      display: flex;
      gap: 20px;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
      }

      .notificationsBtn {
        position: relative;
        cursor: pointer;

        .bellWrapper {
          position: relative;
          display: inline-block;
          transform-origin: top center;
          will-change: transform;

          &:hover {
            animation: bellSwing 0.5s ease-in-out;
          }
        }

        .circle {
          pointer-events: none;
          position: absolute;
          top: 0;
          right: 2px;
          background: $new-white;
          border-radius: 50%;
          width: 6.6px;
          height: 6.6px;
        }
      }

      @keyframes bellSwing {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(15deg);
        }
        50% {
          transform: rotate(-15deg);
        }
        75% {
          transform: rotate(10deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }

      .standardInstallPrice {
        display: flex;
        align-items: center;
        gap: 5px;
        color: $new-white;
        font-size: 14px;
      }
    }

    .menuBtn {
      width: 36px;
      height: 36px;
      padding: 10px 9px 9px 9px;
      flex: 0 0 auto;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.1);
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      @media (min-width: 1000px) {
        display: none;
      }

      svg {
        width: 19px;
        height: 16px;
      }

      @media (max-width: 480px) {
        margin-left: 0;
      }
    }
  }

  .changingElements {
    display: flex;
    gap: 30px;
    align-items: center;

    @media (max-width: 1000px) {
      justify-content: flex-end;
      width: 100%;
      gap: 15px;
    }

    @media (max-width: 435px) {
      .primaryBtn {
        padding: 13px 20px 13px 15px;
        font-size: 15px;

        svg {
          margin-top: 0;
          margin-right: 7px;
        }
      }
    }

    .primaryBtn {
      &.default {
        padding: 13px 20px;
      }
    }
  }
}